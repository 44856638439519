.page-template-page-tuition-fees-repeater {
	.cost-wrap {
		position: relative;

		.triangle-pattern {
			display: block;
			width: 489px;
			height: 539px;
			position: absolute;
			top: 20px;
			left: -100px;
			background: url('/wp-content/themes/cornerstone-2024/svg/triangle-pattern-spread.svg') center no-repeat;
			background-size: contain;
		}

		.inner-wrap {
			width: 1170px;
			position: relative;
			z-index: 2;
			margin: 0 auto;
			padding: 140px 0;

			.info {
				width: 800px;
				margin: 0 auto 65px auto;
			}

			.cu-part__expanders {
				.copy.extra-content {
					width: 725px;
					margin-top: 45px;
				}

				.costs {
					width: 875px;
					margin: 0 auto;
				}

				.items,
				.financial-aid,
				.total {
					padding: 40px 0;
					border-top: 2px solid $gold;

					.item {
						width: calc(100% - 160px);
						margin: 0 auto 20px auto;
						position: relative;
						font-size: 0;

						h3 {
							display: inline-block;
							vertical-align: middle;
							width: 400px;
							margin-right: 50px;
						}

						.description {
							display: block;
							margin-top: 10px;

							font-family: $c_font;
							font-size: 0.777rem;
							font-weight: 600;
							letter-spacing: 0.08em;
							text-transform: uppercase;
							color: $gray;
						}

						.item-cost {
							display: inline-block;
							vertical-align: middle;
							width: calc(100% - 450px);

							font-family: $sc_font;
							font-size: 40px;
							font-weight: 800;
							color: $blue;

							span {
								font-size: 24px;
							}
						}
					}
				}

				.costs {
					margin-top: 45px;
				}

				.financial-aid,
				.total {
					.item {
						width: calc(100% - 160px);
						margin: 0 auto;

						.left {
							display: inline-block;
							vertical-align: middle;
							width: 400px;
							margin-right: 50px;

							h3 {
								display: block;
								width: 100%;
								margin-right: 0;
							}
						}

						.item-cost {
							font-size: 54px;
						}
					}
				}

				.total {
					padding-bottom: 0;
				}
			}

			.note {
				margin-top: 45px;

				p {
					font-size: 0.875rem;
					line-height: 1.4em;
				}
			}
		}

		@include max-down(1300px) {
			.inner-wrap {
				width: 90%;
			}
		}

		@include max-down(1160px) {
			.inner-wrap {
				.cu-part__expanders {
					.copy.extra-content {
						width: 80%;
					}

					.costs {
						width: 80%;
					}

					.items,
					.total {
						.item {
							width: 90%;

							h3 {
								width: 55%;
								margin-right: 5%;
							}

							.item-cost {
								width: 40%;
								font-size: 32px;
							}
						}
					}

					.financial-aid,
					.total {
						.item {
							width: 90%;

							.left {
								width: 55%;
								margin-right: 5%;

								h3 {
									width: 100%;
									margin-right: 0;
								}
							}

							.item-cost {
								font-size: 44px;
							}
						}
						
					}
				}
			}
		}

		@include max-down(1000px) {
			.triangle-pattern {
				display: none;
			}

			.inner-wrap {
				padding: 100px 0;

				.info {
					width: 100%;
				}

				.cu-part__expanders {
					.copy {
						width: 90%;

						&.extra-content {
							width: 90%;
						}
					}
					.costs {
						width: 90%;
					}
				}
			}
		}

		@include max-down(750px) {
			.inner-wrap {
				.cu-part__expanders {
					.items,
					.total {
						.item {
							width: calc(100% - 60px);

							h3 {
								width: 60%;
							}

							.item-cost {
								width: 35%;

								span {
									font-size: 1rem;
								}
							}
						}
					}

					.financial-aid,
					.total {
						.item {
							.left {
								width: 60%;

								h3 {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}

		@include max-down(600px) {
			.inner-wrap {
				width: calc(100% - 40px);

				.cu-part__expanders {
					.items,
					.total {
						.item {
							width: calc(100% - 30px);

							h3 {
								display: block;
								width: 100%;
								margin-right: 0;
							}

							.item-cost {
								display: block;
								width: 100%;
							}
						}
					}

					.financial-aid,
					.total {
						.item {
							width: calc(100% - 30px);
							
							.left {
								display: block;
								width: 100%;
								margin-right: 0;
							}
						}
					}
				}
			}
		}
	}

	.background-wrap {
		position: relative;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: calc(100% - 345px);
			position: absolute;
			top: 0;
			left: 0;
			background: $blue;

			@include max-down(750px) {
				height: calc(100% - 250px);
			}
		}

		&.no-testimonial {
			&:before {
				height: 100%;
			}
		}

		.cu-section__stats {
			padding-top: 140px;
			padding-bottom: 155px;

			@include max-down(750px) {
				padding: 100px 0;
			}
		}
	}

	.expanders-wrap {
		.cu-section__simple-content {
			.inner-wrap {
				padding-top: 195px;
				padding-bottom: 60px;

				@include max-down(1000px) {
					padding-top: 150px;
				}

				@include max-down(750px) {
					padding-top: 100px;
				}
			}
		}

		.cu-part__expanders {
			width: 1170px;
			margin: 0 auto;

			@include max-down(1300px) {
				width: 90%;
			}

			@include max-down(600px) {
				width: calc(100% - 40px);
			}
		}
	}

	.cu-section__contact-bar {
		margin-top: 220px;

		@include max-down(750px) {
			margin-top: 100px;
		}
	}

	.page-background-wrap {
		padding-bottom: 280px;

		@include max-down(750px) {
			padding-bottom: 100px;
		}
	}
}