.single-faculty {
	.cu-section__simple-content {
		padding-bottom: 125px;
	}

	.page-background-wrap {
		padding-bottom: 270px;

		@include max-down(1000px) {
			padding-bottom: 120px;
		}

		@include max-down(600px) {
			padding-bottom: 100px;
		}
	}

	.main-wrap {
		position: relative;
		z-index: 2;
		margin-top: -100px;

		.inner-wrap {
			@include clearfix;
			width: 1170px;
			margin: 0 auto;
		}

		.left {
			width: 430px;
			float: left;

			.image {
				width: 100%;
				height: 380px;
			}
		}

		.right {
			width: 655px;
			float: right;
			padding-top: 160px;

			.inner-content-wrap {
				@include section-line-before(60px, 30px);
				padding-left: 80px;
			}

			.faculty-titles {
				li {
					margin-bottom: 5px;

					font-family: $sc_font;
					font-size: 0.875rem;
					font-weight: 700;
					line-height: 1.375em;
					letter-spacing: 0.02em;
					text-transform: uppercase;
					color: $blue;
				}
			}

			.positions {
				margin-top: 15px;

				li {
					font-family: $n_font;
					font-size: 1rem;
					font-style: italic;
					line-height: 1.555em;
				}
			}

			.copy {
				&:before {
					content: '';
					display: block;
					width: 60px;
					height: 2px;
					background: $gold;
					margin: 40px 0;
				}

				p.pullquote {
					width: 725px;
					margin-left: 0;
					margin-right: 0;

					font-size: 1.333rem;
					line-height: 1.833em;
					text-align: left;

					&:before,
					&:after {
						left: 0;
						transform: none;
					}
				}
			}
		}

		.info,
		.mobile-info {
			margin-top: 20px;
			background: $lgray;
			padding: 25px 30px;

			.list-content {
				margin-top: 35px;

				&:first-child {
					margin-top: 0;
				}

				ul {
					li {
						margin-bottom: 15px;
						font-family: $sc_font;
						font-size: 1rem;
						line-height: 1.333em;

						a {
							color: inherit;
						}

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		.mobile-info {
			display: none;
		}

		@include max-down(1600px) {
			.right .copy p.pullquote {
				width: 100%;
			}
		}

		@include max-down(1300px) {
			.inner-wrap {
				width: 90%;
			}

			.left {
				width: 35%;
			}

			.right {
				width: 60%;
			}
		}

		@include max-down(1000px) {
			.left {
				width: 100%;
				float: none;

				.image {
					width: 430px;
					margin-left: auto;
					margin-right: auto;
				}
			}

			.right {
				width: 100%;
				float: none;
				padding-top: 55px;

				.inner-content-wrap {
					@include section-line-before-break;
					padding-left: 0;

					&:before {
						width: 2px;
						height: 60px;
						margin-left: auto;
						margin-right: auto;
					}

					h1,
					.faculty-titles,
					.positions {
						text-align: center;
					}
				}
			}

			.info {
				display: none;
			}

			.mobile-info {
				display: block;
			}
		}

		@include max-down(600px) {
			.right .inner-content-wrap {
				&:before {
					width: 60px;
					height: 2px;
					margin-left: 0;
					margin-right: 0;
				}

				h1,
				.faculty-titles,
				.positions {
					text-align: left;
				}
			}
		}

		@include max-down(500px) {
			.left .image {
				width: 100%;
				height: auto;
			}
		}
	}

	.extra-content-wrap {
		margin-top: 90px;

		.inner-wrap {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 20px;
			row-gap: 20px;
			width: 1170px;
			margin: 0 auto;
		}

		.block {
			background: $white;
			box-shadow: 6px 6px 24px rgba($black, 0.16);

			&.hobbies,
			&.interests {
				box-shadow: none;

				li {
					margin-bottom: 10px;

					font-family: $c_font;
					font-size: 1rem;
					font-weight: 700;
					letter-spacing: 0.08em;
					text-transform: uppercase;
					color: $white;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			&.hobbies {
				background: $blue;
			}

			&.interests {
				background: $gold;
			}

			.inner-content-wrap {
				width: calc(100% - 100px);
				margin: 0 auto;
				padding: 50px 0;
			}

			h2 {
				margin-bottom: 20px;
			}

			.label-list {
				li {
					position: relative;
					padding-left: 105px;
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0;
					}

					.label {
						display: block;
						position: absolute;
						top: 1px;
						left: 0;

						font-family: $c_font;
						font-size: 1rem;
						font-weight: 700;
						letter-spacing: 0.08em;
						text-transform: uppercase;
						color: $blue;
					}

					.content {
						font-family: $sc_font;
						font-size: 1rem;

						a {
							color: inherit;
							text-decoration: none;

							@include non-touch {
								&:hover {
									text-decoration: underline;
								}
							}
						}
					}
				}
			}
		}

		@include max-down(1300px) {
			.inner-wrap {
				width: 90%;
			}
		}

		@include max-down(1000px) {
			.inner-wrap {
				grid-template-columns: 1fr;
			}
		}

		@include max-down(600px) {
			.block {
				.inner-content-wrap {
					width: calc(100% - 60px);
					padding: 30px 0;
				}

				.label-list {
					li {
						padding-left: 0;

						.label {
							position: static;
							margin-bottom: 5px;
						}
					}
				}
			}
		}
	}

	.view-links-wrap {
		position: relative;
		z-index: 2;
		padding-top: 95px;

		.diamond-pattern {
			content: '';
			display: block;
			width: 609px;
			height: 508px;
			position: absolute;
			top: -60px;
			right: 5px;
			z-index: 1;
			background: url('/wp-content/themes/cornerstone-2024/svg/diamond-pattern-wide.svg') center no-repeat;
			background-size: contain;
		}

		.inner-wrap {
			width: 1170px;
			margin: 0 auto;
		}

		.block {
			position: relative;
			z-index: 2;
			margin-bottom: 20px;
			padding: 50px;

			&:nth-child(1) {
				background: $lgray;
			}

			&:nth-child(2) {
				background: $blue;

				li {
					color: $white;
				}
			}

			&:last-child {
				margin-bottom: 0;
				box-shadow: 6px 6px 24px rgba($black, 0.16);
			}

			.inner-content-wrap {
				width: 930px;
			}

			li {
				position: relative;
				padding-right: 80px;
				margin-bottom: 15px;

				font-family: $c_font;
				font-size: 1rem;
				line-height: 1.125em;
				font-weight: 700;
				letter-spacing: 0.08em;
				text-transform: uppercase;

				&:last-child {
					margin-bottom: 0;
				}

				.button-wrap {
					position: absolute;
					top: -2px;
					right: 0;

					.arrow-btn {
						@include non-touch {
							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}

		@include max-down(1300px) {
			.inner-wrap {
				width: 90%;
			}

			.block .inner-content-wrap {
				width: 100%;
			}
		}

		@include max-down(1000px) {
			.diamond-pattern {
				display: none;
			}
		}

		@include max-down(600px) {
			.block {
				padding: 35px 25px;
			}
		}
	}
}