.page-template-page-general-template-3 {
	.cu-section__stats {
		padding: 25px 0 75px 0;

		.stats {
			box-shadow: 6px 6px 24px rgba($black, 0.16);
		}
	}

	.cu-section__half {
		position: relative;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -70px;
			left: -165px;
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gold.svg') center no-repeat; 
			background-size: contain;

			@include max-down(1160px) {
				bottom: 0;
				left: -300px;
			}

			@include max-down(1000px) {
				display: none;
			}
		}

		.diamond-pattern {
			display: block;
			width: 469px;
			height: 585px;
			position: absolute;
			top: -75px;
			left: 10px;
			background: url('/wp-content/themes/cornerstone-2024/svg/diamond-pattern-gray-5-column.svg') center no-repeat; 
			background-size: contain;

			@include max-down(1160px) {
				width: 400px;
				height: 499px;
				top: -50px;
			}

			@include max-down(1000px) {
				display: none;
			}
		}

		.triangle-pattern {
			display: block;
			width: 353px;
			height: 491px;
			position: absolute;
			top: -80px;
			left: 0;
			background: url('/wp-content/themes/cornerstone-2024/svg/triangle-pattern-gray-wide.svg') center no-repeat;
			background-size: contain;

			@include max-down(1160px) {
				top: -25px;
			}

			@include max-down(1000px) {
				display: none;
			}
		}
	}

	.page-background-wrap {
		padding-top: 75px;
		padding-bottom: 275px;

		@include max-down(1000px) {
			padding-top: 0;
		}

		@include max-down(750px) {
			padding-bottom: 75px;
		}
	}

	.cu-section__contact-bar {
		margin-top: 205px;

		@include max-down(1000px) {
			margin-top: 100px;
		}

		@include max-down(750px) {
			margin-top: 75px;
		}
	}
}