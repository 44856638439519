.cu-section__main-content {
	@include clearfix;
	position: relative;
	z-index: 2;
	padding-top: 70px;
	padding-bottom: 155px;

	&.tbx-animated {
		.images .image-1,
		.images .image-2,
		.info h2,
		.info .copy,
		.info .button-wrap span,
		.info h3,
		.info .main-jump-nav li {
			opacity: 0;

			@include max-down(700px) {
				opacity: 1;
				animation: none !important;
			}
		}

		.info .inner-content-wrap:before {
			width: 0;
			transition: width .7s .7s;
		}

		.info .inner-content-wrap .inner .right:before {
			height: 0;
			transition: height .7s 2.8s;
		}

		&.tbx-animated-siv-ft {
			.images .image-1 {
				@include fadeInLeft($duration: 0.7s);
			}

			.images .image-2 {
				@include fadeInUp($duration: 0.7s, $delay: 0.35s);
			}

			.info .inner-content-wrap:before {
				width: 60px;
			}

			.info h2 {
				@include fadeIn($duration: 0.7s, $delay: 1.4s);
			}

			.info .copy {
				@include fadeIn($duration: 0.7s, $delay: 1.75s);
			}

			.info .button-wrap {
				span {
					&:first-child {
						@include fadeInUp($duration: 0.7s, $delay: 2.1s);
					}

					&:last-child {
						@include fadeInUp($duration: 0.7s, $delay: 2.45s);
					}
				}

				&.three-buttons {
					span {
						&:nth-child(1) {
							@include fadeInUp($duration: 0.7s, $delay: 2.1s);
						}

						&:nth-child(2) {
							@include fadeInUp($duration: 0.7s, $delay: 2.25s);
						}

						&:nth-child(3) {
							@include fadeInUp($duration: 0.7s, $delay: 2.45s);
						}
					}
				}
			}

			.info .inner-content-wrap .inner .right:before {
				height: 100%;
			}

			.info h3 {
				@include fadeIn($duration: 0.7s, $delay: 3.05s);
			}

			.info .main-jump-nav li {
				&:nth-child(1) {
					@include fadeIn($duration: 0.7s, $delay: 3.40s);
				}

				&:nth-child(2) {
					@include fadeIn($duration: 0.7s, $delay: 3.6s);
				}

				&:nth-child(3) {
					@include fadeIn($duration: 0.7s, $delay: 3.8s);
				}

				&:nth-child(4) {
					@include fadeIn($duration: 0.7s, $delay: 4s);
				}

				&:nth-child(5) {
					@include fadeIn($duration: 0.7s, $delay: 4.2s);
				}

				&:nth-child(6) {
					@include fadeIn($duration: 0.7s, $delay: 4.4s);
				}

				&:nth-child(7) {
					@include fadeIn($duration: 0.7s, $delay: 4.6s);
				}

				&:nth-child(8) {
					@include fadeIn($duration: 0.7s, $delay: 4.8s);
				}

				&:nth-child(9) {
					@include fadeIn($duration: 0.7s, $delay: 5.0s);
				}

				&:nth-child(10) {
					@include fadeIn($duration: 0.7s, $delay: 5.2s);
				}
			}
		}
	}

	.diamond-pattern {
		display: block;
		width: 460px;
		height: 585px;
		position: absolute;
		bottom: -170px;
		left: 20px;
		z-index: 1;
		background: url('/wp-content/themes/cornerstone-2024/svg/diamond-pattern-gold-wide.svg') center no-repeat;
		background-size: contain;
	}

	.lines-pattern {
		display: block;
		width: 470px;
		height: 510px;
		position: absolute;
		bottom: 85px;
		left: 40px;
		z-index: 1;
		background: url('/wp-content/themes/cornerstone-2024/svg/lines-pattern-gray.svg') center no-repeat;
		background-size: contain;
	}

	.images {
		width: 800px;
		height: 590px;
		float: left;
		position: relative;
		z-index: 2;

		.image-1 {
			width: 280px;
			height: 280px;
			position: absolute;
			top: 0;
			left: 75px;
			z-index: 2;
		}

		.image-2 {
			width: 575px;
			height: 535px;
			position: absolute;
			top: 55px;
			right: 0;
			z-index: 1;

			.image {
				width: 100%;
				height: 100%;
				position: relative;
				z-index: 1;
			}

			.tbx-module__video-overlay-toggle {
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;

				.icon {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
	}

	.info {
		width: calc(100% - 800px);
		float: right;
		position: relative;
		z-index: 2;
		padding-top: 30px;

		.inner-content-wrap {
			@include section-line-before(60px, 27px);
			width: 820px;
			position: relative;
			margin: 0 auto;
			padding-left: 80px;

			h2 {
				width: 600px;
			}

			.inner {
				@include clearfix;

				.left {
					width: 500px;
					float: left;

					.button-wrap {
						margin-top: 40px;
						font-size: 0;

						span { 
							display: inline-block;
							vertical-align: middle;
							margin-right: 20px;

							&:last-child {
								margin-right: 0;
							}
						}

						&.three-buttons {
							margin-top: 25px;

							span {
								padding-top: 15px;
							}
						}
					}

					.cu-section__share-links {
						margin-top: 50px;
						padding: 0;
						text-align: left;

						h2 {
							width: auto;
						}
					}
				}

				.right {
					width: 165px;
					float: right;
					position: relative;
					padding-left: 35px;
					padding-top: 5px;
					padding-bottom: 5px;

					&:before {
						content: '';
						display: block;
						width: 2px;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						background: $gold;
					}

					h3 {
						margin-bottom: 5px;
						font-family: $c_font;
						font-size: 1rem;
						font-weight: 600;
						letter-spacing: 0.08em;
						text-transform: uppercase;
						color: $gray;
					}

					ul li {
						margin-bottom: 5px;

						&:last-child {
							margin-bottom: 0;
						}

						a {
							font-family: $c_font;
							font-size: 1rem;
							font-weight: 600;
							letter-spacing: 0.08em;
							text-transform: uppercase;
							text-decoration: none;
							color: $blue;

							@include non-touch {
								transition: color .3s;

								&:hover {
									color: $gold;
								}
							}
						}
					}
				}
			}
		}
	}

	&.image-bottom-right {
		.images {
			.image-1 {
				top: auto;
				bottom: 0;
				right: 0;
				left: auto;
			}

			.image-2 {
				top: 0;
				left: 75px;
				right: auto;
			}
		}
	}

	&.image-bottom-left {
		.images {
			.image-1 {
				top: auto;
				bottom: 0;
				left: 75px;
				right: auto;
			}

			.image-2 {
				top: 0;
				right: 0;
				left: auto;
			}
		}
	}

	&.more-top-content-padding {
		.info {
			padding-top: 80px;
		}
	}

	@include max-down(1921px) {
		.info {
			.inner-content-wrap {
				width: 90%;

				.inner {
					.left {
						width: calc(100% - 225px);
					}
				}
			}
		}
	}

	@include max-down(1600px) {
		.images {
			width: 600px;
			height: 495px;

			.image-1 {
				width: 200px;
				height: 200px;
			}

			.image-2 {
				width: 425px;
				height: 395px;
			}
		}

		.info {
			width: calc(100% - 600px);

			.inner-content-wrap {
				h2 {
					width: 100%;
				}
			}
		}
	}

	@include max-down(1400px) {
		.info {
			.inner-content-wrap {
				@include section-line-before-break;
				padding-left: 0;
				width: 80%;
			}
		}
	}

	@include max-down(1300px) {
		.info {
			.inner-content-wrap .inner {
				.left {
					width: 100%;
					float: none;
				}

				.right {
					display: none;
				}
			}
		}
	}

	@include max-down(1000px) {
		padding-top: 55px;
		padding-bottom: 55px;

		.diamond-pattern,
		.lines-pattern {
			display: none;
		}

		.images {
			float: none;
			margin: 0 auto;

			.image-1 {
				left: 0;
			}

			.image-2 {
				width: 473px;
				height: 440px;
			}
		}

		.info {
			width: 100%;
			float: none;
			padding-top: 55px;
		}

		&.image-bottom-right {
			.images .image-2 {
				left: 0;
			}
		}

		&.image-bottom-left {
			.images .image-1 {
				left: 0;
			}
		}
	}

	@include max-down(700px) {
		.images {
			width: 90%;
			height: auto;
			position: static;

			.image-1,
			.image-3 {
				display: none;
			}

			.image-2 {
				width: 100%;
				height: auto;
				position: relative;
				top: auto; right: auto; bottom: auto; left: auto;
			}
		}

		.info {
			padding-top: 55px !important;
		}
	}

	@include max-down(600px) {
		padding-top: 20px;

		.images {
			width: calc(100% - 40px);
		}

		.info {
			.inner-content-wrap {
				width: calc(100% - 40px);

				&:before {
					width: 40px;
				}

				.inner .left .button-wrap {
					&.three-buttons {
						span {
							padding-top: 0;
						}
					}

					span {
						display: block;
						margin-right: 0;
						margin-bottom: 15px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}