.single-event {
	.cu-section__simple-content {
		.inner-wrap {
			padding-bottom: 215px;
		}

		@include max-down(1350px) {
			.inner-wrap {
				padding-bottom: 100px;
			}
		}
	}

	.main-wrap {
		position: relative;
		z-index: 2;
		margin-top: -105px;

		.image {
			width: 1470px;
			margin: 0 auto;
		}

		.event {
			@include clearfix;
			width: 1170px;
			margin: 110px auto 115px auto;

			.details {
				width: 500px;
				float: left;
				background: $blue;

				.inner-content-wrap {
					width: calc(100% - 100px);
					margin: 0 auto;
					padding: 50px 0;
				}

				.event-details {
					margin-top: 25px;

					.detail {
						@include clearfix;
						margin-bottom: 20px;

						&:last-child {
							margin-bottom: 0;
						}

						h3 {
							width: 145px;
							float: left;

							font-family: $c_font;
							font-size: 1rem;
							font-weight: 600;
							letter-spacing: 0.08em;
							text-transform: uppercase;
							color: $gold;
						}

						p {
							width: calc(100% - 145px);
							float: right;
							margin-bottom: 0;

							font-family: $c_font;
							font-size: 1rem;
							font-weight: 700;
							line-height: 1.222em;
							letter-spacing: 0.08em;
							text-transform: uppercase;
							color: $white;
						}
					}
				}

				.button-wrap {
					margin-top: 35px;
				}
			}

			.description {
				width: 575px;
				float: right;
			}
		}

		@include max-down(1600px) {
			.image {
				width: 90%;
			}
		}

		@include max-down(1350px) {
			margin-top: 0;

			.image {
				width: 100%;
			}
		}

		@include max-down(1300px) {
			.event {
				width: 90%;

				.details {
					width: 45%;
				}

				.description {
					width: 50%;
				}
			}
		}

		@include max-down(1000px) {
			.event {
				margin: 100px auto;

				.details {
					width: 100%;
					float: none;
					margin-top: 100px;
				}

				.description {
					width: 100%;
					float: none;
				}
			}
		}

		@include max-down(600px) {
			.event {
				width: calc(100% - 40px);
				margin: 75px auto;

				.details {
					margin-top: 75px;

					.inner-content-wrap {
						width: calc(100% - 80px);
						padding: 40px 0;
					}

					.event-details {
						.detail {
							h3 {
								width: 100%;
								float: none;
							}

							p {
								width: 100%;
								float: none;
								margin-top: 10px;
							}
						}
					}
				}
			}
		}
	}

	.contact-bar-wrap {
		position: relative;

		.diamond-pattern {
			display: block;
			width: 469px;
			height: 585px;
			position: absolute;
			top: -240px;
			left: 10px;
			background: url('/wp-content/themes/cornerstone-2024/svg/diamond-pattern-gray-5-column.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(1000px) {
			.diamond-pattern {
				display: none;
			}
		}
	}

	.embed-wrap {
		position: relative;
		z-index: 2;
		padding: 140px 0 0 0;

		.inner-wrap {
			width: 1170px;
			min-height: 800px;
			margin: 0 auto;

			iframe {
				width: 100%;
			}
		}

		@include max-down(1300px) {
			.inner-wrap {
				width: 90%;
			}
		}

		@include max-down(1000px) {
			padding-top: 100px;
		}

		@include max-down(600px) {
			padding-top: 75px;

			.inner-wrap {
				width: calc(100% - 40px);
			}
		}
	}

	.cu-section__post-list {
		position: relative;
		padding-top: 0;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -135px;
			left: -185px;
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;
		}

		> .info {
			position: relative;
			z-index: 2;
		}

		.post-list {
			position: relative;
			z-index: 2;
		}

		@include max-down(1000px) {
			.plus-pattern {
				display: none;
			}
		}
	}
}