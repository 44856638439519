.page-template-page-general-template-9 {
	.cu-section__half {
		position: relative;

		.triangle-pattern {
			display: block;
			width: 556px;
			height: 670px;
			position: absolute;
			top: 50px;
			right: 0;
			background: url('/wp-content/themes/cornerstone-2024/svg/triangle-pattern-spread.svg') center no-repeat;
			background-size: contain;
		}
	}

	.cu-section__faculty-list {
		position: relative;
		z-index: 2;
		
		.faculty-list {
			margin-top: 0;
		}
	}

	.contact-bar-wrap {
		position: relative;
		padding-bottom: 175px;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -30px;
			left: -185px;
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(600px) {
			padding-bottom: 100px;

			.plus-pattern {
				display: none;
			}
		}
	}
}