.page-template-page-experience {
	.cu-section__stats {
		padding: 0 0 165px 0;

		@include max-down(1000px) {
			padding-top: 75px;
		}

		@include max-down(750px) {
			padding-top: 25px;
			padding-bottom: 100px;
		}
	}

	.background-wrap {
		position: relative;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: calc(100% - 445px);
			position: absolute;
			bottom: 25px;
			left: 0;
			background: $blue;
		}

		.testimonial-wrap,
		.cu-section__half {
			position: relative;
			z-index: 2;
		}

		.testimonial-wrap {
			&:before {
				content: '';
				display: block;
				width: 707px;
				height: 654px;
				position: absolute;
				top: -170px;
				right: -80px;
				z-index: 1;
				transform: rotate(-90deg);
				background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gold.svg') center no-repeat;
				background-size: contain;
			}

			.cu-section__testimonial {
				position: relative;
				z-index: 2;
			}
		}

		.cu-section__half.has-pattern {
			.triangle-pattern {
				display: block;
				width: 353px;
				height: 491px;
				position: absolute;
				top: -95px;
				left: 0;
				z-index: 1;
				opacity: 0.13;
				background: url('/wp-content/themes/cornerstone-2024/svg/triangle-pattern-gray-wide.svg') center no-repeat;
				background-size: contain;
			}

			.image-3 {
				z-index: 2;
			}

			.inner-wrap {
				z-index: 3;
			}
		}

		@include max-down(1000px) {
			padding-bottom: 100px;

			&:before {
				height: calc(100% - 400px);
				bottom: 0;
			}

			.testimonial-wrap {
				&:before {
					top: -100px;
				}
			}

			.cu-section__half.has-pattern .triangle-pattern {
				display: none;
			}
		}

		@include max-down(750px) {
			.testimonial-wrap {
				&:before {
					display: none;
				}
			}
		}
	}

	.page-background-wrap {
		padding-bottom: 365px;

		#off-campus-study-programs {
			position: relative;
			padding-top: 170px;

			.diamond-pattern {
				display: block;
				width: 470px;
				height: 660px;
				position: absolute;
				top: -230px;
				left: 10px;
				background: url('/wp-content/themes/cornerstone-2024/svg/diamond-pattern-gold-5-column.svg') center no-repeat;
				background-size: contain;
			}

			@include max-down(650px) {
				padding-top: 100px;
			}

			@include max-down(600px) {
				.diamond-pattern {
					display: none;
				}
			}
		}
	}

	.cu-section__image-slider {
		margin-top: -200px;
	}

	.contact-bar-wrap {
		position: relative;
		padding-top: 100px;
		padding-bottom: 175px;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -95px;
			left: -210px;
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gray.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(1000px) {
			padding-top: 150px;
		}

		@include max-down(600px) {
			padding-top: 20px;
			padding-bottom: 100px;

			.plus-pattern {
				display: none;
			}
		}
	}
}