.page-template-page-general-template-5 {
	.forms-wrap {
		position: relative;
		z-index: 2;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: 35px;
			right: -30px;
			transform: rotate(-90deg);
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;

			@include max-down(1160px) {
				width: 500px;
				height: 463px;
			}

			@include max-down(1000px) {
				display: none;
			}
		}
	}

	.benefits-wrap {
		position: relative;
		z-index: 1;
		margin-top: -105px;
		background: $blue;

		&.has-testimonial {
			background: none;

			.blue-background {
				display: block;
				width: 100%;
				height: calc(100% - 460px);
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				background: $blue;
			}

			.plus-pattern {
				bottom: 370px;
				z-index: 2;
			}

			.cu-section__simple-content,
			.cu-section__number-list {
				position: relative;
				z-index: 3;
			}

			@include max-down(750px) {
				.blue-background {
					height: calc(100% - 200px);
				}
			}
		}

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -250px;
			left: -250px;
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gray.svg') center no-repeat;
			background-size: contain;

			@include max-down(1700px) {
				left: -350px;
			}

			@include max-down(1400px) {
				left: -450px;
			}

			@include max-down(1160px) {
				width: 500px;
				height: 463px;
				left: -250px;
			}

			@include max-down(1000px) {
				display: none;
			}
		}

		.cu-section__simple-content {
			.inner-wrap {
				padding-top: 145px;
				padding-bottom: 0;

				@include max-down(1000px) {
					padding-top: 100px;
				}
			}
		}

		.cu-section__number-list {
			padding-top: 50px;
			padding-bottom: 180px;

			@include max-down(1000px) {
				padding-bottom: 100px;
			}
		}

		@include max-down(1160px) {
			margin-top: 100px;
		}

		.testimonial-wrap {
			position: relative;
			z-index: 3;
		}
	}

	.resources-wrap {
		.cu-section__simple-content {
			.inner-wrap {
				padding-top: 110px;
			}
		}
		.cu-part__expanders {
			width: 1170px;
			margin: 0 auto;

			@include max-down(1300px) {
				width: 90%;
			}

			@include max-down(600px) {
				width: calc(100% - 40px);
			}
		}
	}

	.cu-section__contact-bar {
		margin-top: 190px;

		@include max-down(750px) {
			margin-top: 100px;
		}
	}

	.page-background-wrap {
		padding-bottom: 280px;

		@include max-down(750px) {
			padding-bottom: 100px;
		}
	}
}