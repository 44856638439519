.cu-section__colors {
	position: relative;
	padding-bottom: 230px;

	&.tbx-animated {
		.inner-wrap {
			opacity: 0;

			.inner-content-wrap .grid .grid-item {
				opacity: 0;
			}
		}

		&.tbx-animated-siv-ft {
			.inner-wrap {
				@include fadeInUp($duration: 0.7s);

				.inner-content-wrap .grid .grid-item {
					&:nth-child(1) {
						@include fadeInLeft($duration: 0.7s, $delay: 0.5s);
					}

					&:nth-child(2) {
						@include fadeInDown($duration: 0.7s, $delay: 0.9s);
					}

					&:nth-child(3) {
						@include fadeInUp($duration: 0.7s, $delay: 0.7s);
					}

					&:nth-child(4) {
						@include fadeInRight($duration: 0.7s, $delay: 1.1s);
					}
				}
			}
		}

		@include max-down(650px) {
			.inner-wrap {
				opacity: 1;
				animation: none !important;

				.inner-content-wrap .grid .grid-item {
					opacity: 1;
					animation: none !important;
				}
			}
		}
	}

	&:after {
		content: '';
		width: 100%;
		height: calc(100% - 230px);
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1;
		background: $blue;
	}

	.triangle-pattern {
		display: block;
		width: 240px;
		height: 334px;
		position: absolute;
		bottom: 318px;
		right: -60px;
		z-index: 2;
		background: url('/wp-content/themes/cornerstone-2024/svg/triangle-pattern-gold.svg') center no-repeat;
		background-size: contain;
	}

	.diamond-pattern {
		display: block;
		width: 345px;
		height: 585px;
		position: absolute;
		bottom: 50px;
		left: -40px;
		z-index: 2;
		background: url('/wp-content/themes/cornerstone-2024/svg/diamond-pattern-gold.svg') center no-repeat;
		background-size: contain;
	}

	.plus-pattern {
		display: block;
		width: 707px;
		height: 654px;
		position: absolute;
		bottom: 20px;
		left: -150px;
		z-index: 2;
		background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gold.svg') center no-repeat;
		background-size: contain;
	}

	.mobile-image {
		display: none;
	}

	&.no-bg {
		&:after {
			display: none;
		}
	}

	.inner-wrap {
		width: 1770px;
		position: relative;
		z-index: 3;
		margin: 0 auto;
		padding: 95px 0;

		.mobile-background {
			display: none;
		}

		.inner-content-wrap {
			width: 1470px;
			margin: 0 auto;

			.grid {
				display: grid;
				width: 780px;
				margin: 0 0 0 auto;

				grid-template-columns: 1fr 1fr 1fr;
				grid-template-rows: 1fr 1fr;

				.grid-item {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 260px;
					height: 260px;

					&:nth-child(1) {
						@include cutout-triangle-left;
					}

					&:nth-child(2) {
						grid-column: 3;
						grid-row: 1;
					}

					&:nth-child(3) {
						grid-column: 2;
						grid-row: 2;
					}

					&:nth-child(4) {
						@include cutout-triangle-right;
						grid-column: 3;
						grid-row: 2;
					}

					&.link {
						background: $white;

						a {
							display: flex;
							width: 100%;
							height: 100%;

							align-items: center;
							justify-content: center;

							text-decoration: none;
						}

						.inner-content-wrap {
							h2 {
								margin-bottom: 0;

								font-family: $s_font;
								font-size: 60px;
								line-height: 52px;
								color: $blue;
							}

							.plus {
								display: block;
								width: 30px;
								height: 30px;
								position: relative;
								margin: 30px auto 0 auto;

								&:before {
									content: '';
									display: block;
									width: 100%;
									height: 4px;
									position: absolute;
									top: calc(50% - 2px);
									left: 0;
									background: $gold;
								}

								&:after {
									content: '';
									display: block;
									width: 4px;
									height: 100%;
									position: absolute;
									top: 0;
									left: calc(50% - 2px);
									background: $gold;
								}
							}
						}
					}

					&.stat {
						&.blue {
							background: $blue;
						}

						&.gold {
							background: $gold;
						}

						.inner-content-wrap {
							dl {
								dt {
									font-family: $ec_font;
									font-weight: 300;
									font-size: 124px;
									line-height: 110px;
									color: $white;
								}

								dd {
									margin-top: 15px;

									font-family: $n_font;
									font-size: 0.77rem;
									font-weight: 700;
									line-height: 1.125rem;
									letter-spacing: 0.01em;
									color: $white;
								}
							}
						}
					}

					.inner-content-wrap {
						width: 190px;
						margin: 0 auto;
						text-align: center;
					}
				}
			}
		}
	}

	@include max-down(1920px) {
		.inner-wrap {
			width: 90%;

			.inner-content-wrap {
				width: 90%;
			}
		}
	}

	@include max-down(1160px) {
		.inner-wrap {
			.inner-content-wrap {
				.grid {
					width: 520px;
					grid-template-columns: 1fr 1fr;
					margin: 0 auto;

					.grid-item {
						grid-column: unset !important;
						grid-row: unset !important;
					}
				}
			}
		}
	}

	@include max-down(650px) {
		padding-bottom: 80px;

		.diamond-pattern {
			display: none;
		}

		.mobile-image {
			display: block;
			height: 350px;
		}

		&.no-bg {
			.inner-wrap .inner-content-wrap {
				margin-top: 0;

				.grid .grid-item.link {
					background: $blue;

					.inner-content-wrap {
						h2 {
							color: $white;
						}
					}
				}
			}
		}

		.inner-wrap {
			width: 100%;
			background: none !important;
			padding: 0;

			.mobile-background {
				display: block;
				width: 100%;
				height: 485px;
				position: relative;
				z-index: 1;
			}

			.inner-content-wrap {
				width: 100%;
				position: relative;
				z-index: 2;
				margin-top: -160px;

				.grid {
					display: flex;
					flex-direction: column;
					width: calc(100% - 120px);
					margin: 0 auto;

					.grid-item {
						width: 100%;
						height: 200px;
						flex-grow: 1;

						&.link .inner-content-wrap {
							width: 185px;
							
							h2 {
								font-size: 48px;
							}

							.plus {
								margin-top: 25px;
							}
						}

						&.stat {
							.inner-content-wrap dl {
								dt {
									font-size: 104px;
									line-height: 80px;
								}
							}

							&.blue {
								background: $gold;
							}
						}

						&:nth-child(1) {
							order: 4;
						}

						&:nth-child(2) {
							order: 1;
						}

						&:nth-child(3) {
							order: 3;
						}

						&:nth-child(4) {
							order: 2;
						}
					}
				}
			}
		}
	}

	@include max-down(475px) {
		.inner-wrap .inner-content-wrap .grid {
			width: calc(100% - 80px);
		}
	}
}