.cu-section__loading-overlay {
	display: none;
	width: 100%;
	max-width: 1920px;
	height: 100vh;
	position: fixed;
	top: 0;
	z-index: 20;
	right: calc((100% - 1920px)/2);
	background: rgba($white, 0.8);

	&:after {
		content: '';
		display: block;
		width: 100px;
		height: 100px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(-27deg);
		background: url('/wp-content/themes/cornerstone-2024/svg/loading.svg') center no-repeat;
		background-size: contain;
	}

	@include max-down(1921px) {
		right: 0;
	}
}