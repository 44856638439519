.alt-header .landing-page-header-wrap {
	background: $white;
	
	.header {
		.logo {
			background: url('/wp-content/themes/cornerstone-2024/svg/logo-blue.svg') center no-repeat;
			background-size: contain;

			@include max-down(600px) {
				background: url('/wp-content/themes/cornerstone-2024/svg/logo-mark.svg') center no-repeat;
				background-size: contain;
			}
		}

		.header-left a {
			color: $blue;
		}

		.header-right a {
			color: $blue;
		}

		.header-right .mobile-menu-toggle .icon span {
			background: $blue;
		}
	}
}

.landing-page-header-wrap {
	width: 100%;
	max-width: 1920px;
	position: absolute;
	top: 0;
	right: calc((100% - 1920px)/2);
	z-index: 20;
	background: none;
	padding: 40px 0;
	transition: top .3s;

	.mobile-cta {
		display: none;
		align-items: center;
		justify-content: space-around;
		padding: 20px;
		margin-bottom: 25px;
		background: $blue;
		text-align: center;
		font-size: 0;
	}

	.header {
		position: relative;

		.logo {
			@include hidden-text;
			display: block;
			width: 258px;
			height: 48px;
			margin: 0 auto;
			background: url('/wp-content/themes/cornerstone-2024/svg/logo-white.svg') center no-repeat;
			background-size: contain;
		}

		.header-left {
			position: absolute;
			top: 50%;
			left: 40px;
			transform: translateY(-50%);

			a {
				position: relative;
				font-family: $c_font;
				font-size: 1rem;
				font-weight: 600;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				text-decoration: none;
				color: $white;

				&:after {
					content: '';
					display: block;
					width: 0;
					height: 6px;
					position: absolute;
					bottom: -10px;
					left: 0;
					background: $gold;
				}

				@include non-touch {
					&:after {
						transition: width .3s;
					}

					&:hover {
						&:after {
							width: 100%;
						}
					}
				}
			}

			.mobile {
				display: none;
			}
		}

		.header-right {
			position: absolute;
			top: 50%;
			right: 40px;
			transform: translateY(-50%);

			> span {
				display: inline-block;
				vertical-align: middle;
				margin-right: 20px;

				&:last-child {
					margin-right: 0;
				}
			}

			a {
				position: relative;
				font-family: $c_font;
				font-size: 1rem;
				font-weight: 600;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				text-decoration: none;
				color: $white;

				&:after {
					content: '';
					display: block;
					width: 0;
					height: 6px;
					position: absolute;
					bottom: -10px;
					left: 0;
					background: $gold;
				}

				@include non-touch {
					&:after {
						transition: width .3s;
					}

					&:hover {
						&:after {
							width: 100%;
						}
					}
				}
			}

			.mobile-menu-toggle {
				display: none;
				vertical-align: middle;
				margin-left: 20px;
				font-size: 0;
				padding-top: 4px;

				.icon {
					display: inline-block;
					vertical-align: middle;
					width: 30px;
					height: 20px;
					position: relative;

					span {
						display: block;
						width: 100%;
						height: 2px;
						position: absolute;
						top: 0;
						right: 0;
						background: $white;
						transition: all .3s;

						&:nth-child(2) {
							width: 20px;
							top: 9px;
						}

						&:nth-child(3) {
							width: 25px;
							top: 18px;
						}
					}
				}

				&.active {
					.icon {
						span {
							width: 100% !important;
							top: 50% !important;
							background: $gold;

							&:nth-child(1) {
								transform: rotate(45deg);
							}

							&:nth-child(2) {
								width: 0 !important;
							}

							&:nth-child(3) {
								transform: rotate(-45deg);
							}
						}
					}
				}

				@include non-touch {
					&:hover {
						.icon span {
							width: 100% !important;
						}
					}
				}
			}
		}
	}

	@include max-down(1921px) {
		right: 0;
	}

	@include max-down(1000px) {
		top: 0;
		padding: 0;

		.mobile-cta {
			display: flex;
		}

		.header {
			.header-left {
				.desktop {
					display: none;
				}

				.mobile {
					display: inline-block;
				}
			}

			.header-right {
				> span {
					display: none;
				}

				.mobile-menu-toggle {
					display: inline-block;
				}
			}
		}
	}

	@include max-down(600px) {
		.header {
			.logo {
				width: 58px;
				height: 60px;
				background: url('/wp-content/themes/cornerstone-2024/svg/logo-mark-white.svg') center no-repeat;
				background-size: contain;
			}

			.header-left {
				left: 15px;
			}

			.header-right {
				right: 15px;
			}
		}
	}

	@include max-down(500px) {
		.mobile-cta {
			.text-btn {
				font-size: 14px;
			}
		}
	}
}

.landing-page-scrolled-header-wrap {
	width: 100%;
	max-width: 1920px;
	position: fixed;
	top: 0;
	right: calc((100% - 1920px)/2);
	z-index: 30;
	background: $white;
	box-shadow: 2px 2px 12px rgba($black, 0.24);
	transform: translateY(-100%);
	transition: transform .4s;

	&.scrolled {
		transform: translateY(0);
	}

	.logo {
		@include hidden-text;
		display: block;
		width: 58px;
		height: 60px;
		position: absolute;
		top: 50%;
		left: 40px;
		transform: translateY(-50%);
		background: url('/wp-content/themes/cornerstone-2024/svg/logo-mark.png') center no-repeat;
		background-size: contain;
	}

	.scrolled-header-nav {
		position: relative;
		text-align: center;

		.bottom-line {
			display: block;
			width: 100px;
			height: 10px;
			position: absolute;
			bottom: 0;
			left: 0;
			background: $gold;
		}

		li {
			display: inline-block;
			vertical-align: middle;
			margin-right: 55px;

			&:last-child {
				margin-right: 0;
			}

			a {
				display: block;
				position: relative;
				font-family: $sc_font;
				font-size: 0.875rem;
				font-weight: 700;
				line-height: 80px;
				letter-spacing: 0.02em;
				color: $blue;
				text-decoration: none;
			}
		}
	}

	.scrolled-header-right,
	.scrolled-header-right-mobile {
		position: absolute;
		top: 50%;
		right: 40px;
		transform: translateY(-50%);

		span {
			display: inline-block;
			vertical-align: middle;
			margin-right: 20px;

			&:last-child {
				margin-right: 0;
			}

			.btn {
				width: 120px;
				min-width: 0;
				font-size: 0.875rem;
				letter-spacing: 0.02em;
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	.scrolled-header-right-mobile {
		display: none;
	}

	.mobile-menu-toggle {
		display: none;
		float: right;
		width: 40px;
		height: 40px;
		margin-top: 10px;

		.icon {
			display: block;
			width: 100%;
			height: 20px;
			position: relative;

			span {
				display: block;
				width: 30px;
				height: 2px;
				position: absolute;
				top: 0;
				left: 0;
				background: $gold;
				transition: all .3s;

				&:nth-child(2) {
					top: 9px;
					left: auto;
					right: 0;
				}

				&:nth-child(3) {
					top: 18px;
				}
			}
		}

		@include non-touch {
			&:hover {
				.wrap span {
					width: 100%;
				}
			}
		}

		&.active {
			.icon {
				span {
					width: 100%;
					top: 50%;
					left: 0;

					&:nth-child(1) {
						transform: rotate(45deg);
					}

					&:nth-child(2) {
						width: 0;
					}

					&:nth-child(3) {
						transform: rotate(-45deg);
					}
				}
			}
		}
	}

	@include max-down(1921px) {
		right: 0;
	}

	@include max-down(1700px) {
		.scrolled-header-nav {
			transform: translateX(-5%);
		}
	}

	@include max-down(1500px) {
		.scrolled-header-nav {
			transform: translateX(-7.5%);

			li {
				margin-right: 25px;
			}
		}
	}

	@include max-down(1260px) {
		padding: 10px 40px;

		.logo {
			position: static;
			float: left;
			transform: none;
		}

		.scrolled-header-nav {
			display: none;
		}

		.scrolled-header-right {
			right: 120px;
		}

		.mobile-menu-toggle {
			display: block;
		}
	}

	@include max-down(600px) {
		padding: 10px 15px;

		.scrolled-header-right {
			display: none;
		}

		.scrolled-header-right-mobile {
			display: block;
			right: 15px;
		}

		.mobile-menu-toggle {
			display: none;
		}
	}

	@include max-down(375px) {
		.scrolled-header-right-mobile {
			.text-btn {
				font-size: 14px;
			}
		}
	}
}