.page-template-page-hub-financial-aid {
	.tables {
		width: 1170px;
		margin: 0 auto;
		padding-bottom: 100px;

		.table {
			overflow-x: auto;
			margin-bottom: 40px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		table {
			width: 100%;

			thead {
				th {
					background: $blue;
					padding: 25px 40px;

					font-family: $c_font;
					font-size: 20px;
					font-weight: 600;
					letter-spacing: 0.08em;
					color: $white;
					text-transform: uppercase;
					text-align: left;

					&:first-child {
						width: 60%;
					}

					&:last-child {
						width: 40%;
					}
				}
			}

			tbody {
				tr:nth-child(2n) {
					th,
					td {
						background: $lgray;
					}
				}

				th,
				td {
					padding: 12px 40px 15px;

					font-family: $c_font;
					font-size: 20px;
					font-weight: 500;
					color: $dblue;
					text-align: left;
				}
			}
		}

		@include max-down(1300px) {
			width: 90%;
		}

		@include max-down(600px) {
			padding-bottom: 65px;

			table {
				thead th {
					padding: 15px;

					font-size: 16px;
				}

				tbody th,
				tbody td {
					padding: 12px 15px 15px;

					font-size: 16px;
				}
			}
		}
	}

	.testimonial-wrap {
		position: relative;

		.diamond-pattern {
			display: block;
			width: 469px;
			height: 445px;
			position: absolute;
			bottom: 115px;
			left: 10px;
			z-index: 1;
			background: url('/wp-content/themes/cornerstone-2024/svg/diamond-pattern-gold-5-column.svg') top center no-repeat;
			background-size: 469px 585px;
		}

		.cu-section__testimonial {
			z-index: 2;
		}

		@include max-down(1000px) {
			.diamond-pattern {
				display: none;
			}
		}
	}

	.expanders-wrap {
		padding-bottom: 100px;

		@include max-down(600px) {
			padding-bottom: 65px;
		}

		.cu-section__simple-content {
			.inner-wrap {
				padding-top: 100px;
				padding-bottom: 60px;

				@include max-down(600px) {
					padding-top: 65px;
				}
			}
		}

		.cu-part__expanders {
			width: 1170px;
			margin: 0 auto;

			@include max-down(1300px) {
				width: 90%;
			}

			@include max-down(600px) {
				width: calc(100% - 40px);
			}
		}
	}
}