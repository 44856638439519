.cu-section__filter-bar {
	width: 1470px;
	position: relative;
	z-index: 2;
	margin: 70px auto 40px auto;
	border-bottom: 2px solid $gold;

	.filter-options {
		@include clearfix;
		position: relative;
		z-index: 2;

		.filters {
			float: left;
			font-size: 0;

			.label,
			.inline-filter,
			.filter-toggle,
			#clear-filters {
				display: inline-block;
				padding: 0 15px 8px 15px;
				font-family: $c_font;
				font-size: 1rem;
				line-height: 1.777em;
				font-weight: 600;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				color: $blue;

				&.active {
					background: $lgray;
				}
			}

			.label {
				padding-left: 0;
			}

			.inline-filter {
				padding-left: 0;

				.inline-filter-item {
					display: inline-block;
					vertical-align: middle;
					margin-right: 30px;

					&:last-child {
						margin-right: 0;
					}

					input[type="radio"] {
						display: none;
					}

					label {
						position: relative;
						padding-left: 24px;

						&:before {
							content: '';
							display: block;
							width: 17px;
							height: 17px;
							position: absolute;
							top: 6px;
							left: 0;
							border: 1px solid $gray;
							border-radius: 100%;
							background: $white;
						}
					}

					input[type="radio"]:checked ~ label:before {
						background: $dblue;
						border-color: $dblue;
					}
				}
			}

			.filter-toggle {
				&:after {
					content: '';
					display: inline-block;
					vertical-align: middle;
					width: 9px;
					height: 8px;
					margin-left: 10px;
					transform: translateY(-1px);
					background: url('/wp-content/themes/cornerstone-2024/svg/filter-arrow-blue.svg') center no-repeat;
					background-size: contain;
				}

				&.active {
					&:after {
						transform: translateY(-2px) rotate(180deg);
					}
				}
			}

			#clear-filters {
				&:after {
					content: '';
					display: inline-block;
					vertical-align: middle;
					width: 8px;
					height: 8px;
					margin-left: 10px;
					transform: translateY(-1px);
					background: url('/wp-content/themes/cornerstone-2024/svg/close-blue.svg') center no-repeat;
					background-size: contain;
				}
			}

			.label,
			.filter,
			.clear-filters {
				display: inline-block;
				vertical-align: middle;
				margin-right: 20px;

				&:last-child {
					margin-right: 0;
				}

				&.hidden {
					display: none;
				}
			}
		}

		.search {
			float: right;

			input[type="text"] {
				appearance: none;
				display: block;
				width: 180px;
				height: 32px;
				border: 0;
				border-radius: 16px;
				outline: 0;
				background: $lgray;
				padding: 0 55px 0 20px;

				font-family: $c_font;
				font-size: 1rem;
				line-height: 1em;
				font-weight: 600;
				letter-spacing: 0.08em;
				color: $blue;
				text-align: left;

				transition: width .3s;

				&:placeholder {
					opacity: 1;
					color: $blue;
				}

				&::placeholder {
					opacity: 1;
					color: $blue;
				}

				&:focus {
					width: 250px;
				}
			}

			button[type="submit"] {
				@include hidden-text;
				width: 15px;
				min-width: 0;
				height: 16px;
				position: absolute;
				top: 8px;
				right: 20px;
				border: 0;
				padding: 0;
				font-size: 0;
				background: url('/wp-content/themes/cornerstone-2024/svg/search-icon-blue.svg') center no-repeat;
				background-size: contain;
			}
		}
	}

	.filter-list {
		display: none;
		width: 100%;
		background: $lgray;
		overflow: auto;

		.inner-content-wrap {
			width: calc(100% - 200px);
			margin: 0 auto;
			padding: 25px 0 35px 0;
		}

		ul {
			columns: 3;
			padding-bottom: 15px;

			li {
				margin-bottom: 20px;
				break-inside: avoid-column;
				page-break-inside: avoid;
			}
		}

		input[type="checkbox"] {
			display: none;
		}

		label {
			display: block;
			position: relative;
			padding-left: 25px;

			font-family: $c_font;
			font-size: 1rem;
			line-height: 1.2em;
			font-weight: 600;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			color: $blue;
			text-align: left;

			&:before {
				content: '';
				display: block;
				width: 14px;
				height: 14px;
				position: relative;
				top: 18px;
				left: -24px;
				border: 1px solid $gray;
				background: $white;
			}
		}

		input[type="checkbox"]:checked + label:before {
			background: $gold;
			border-color: $gold;
		}

		.button-wrap {
			margin-top: 15px;
		}
	}

	.active-filters {
		margin-top: 15px;
		padding-bottom: 25px;
		position: relative;
		z-index: 1;

		button {
			display: inline-block;
			vertical-align: middle;
			padding: 10px;
			margin-right: 10px;
			background: $lgray;

			font-family: $c_font;
			font-size: 0.777rem;
			font-weight: 600;
			letter-spacing: 0.04em;
			text-transform: uppercase;
			color: $blue;

			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				width: 8px;
				height: 8px;
				margin-right: 10px;
				transform: translateY(-1px);
				background: url('/wp-content/themes/cornerstone-2024/svg/close-blue.svg') center no-repeat;
				background-size: contain;
			}
		}

		&.hidden {
			display: none;
		}
	}

	@include max-down(1600px) {
		width: 90%;
	}

	@include max-down(1000px) {
		display: none;

		&.filter-program,
		&.filter-plo,
		.page-template-page-hub-academics &,
		.page-template-page-hub-academics-degree-type &,
		.page-template-page-hub-academics-location & {
			display: block;
			position: relative;
			border-top: 1px solid $gold;
			padding: 25px 20px 75px;

			.filter-options {
				position: static;
			}

			.filters {
				float: none;
				width: 100%;

				.label {
					display: block;
					width: 100%;
					padding-bottom: 0;
					padding-right: 0;
					margin-right: 0;
				}

				.inline-filter {
					display: block;
					padding-bottom: 0;
					padding-right: 0;
					margin-bottom: 5px;
				}

				.filter {
					.filter-toggle {
						padding: 0;

						&.active {
							background: none;
						}
					}
				}

				.clear-filters {
					#clear-filters {
						padding: 0;
					}
				}
			}

			.search {
				width: calc(100% - 40px);
				position: absolute;
				left: 20px;
				bottom: 0;
				float: none;
				margin-top: 20px;
				margin-bottom: 20px;

				input[type="text"] {
					width: 100% !important;
				}
			}

			.filter-list {
				margin-top: 20px;

				.inner-content-wrap {
					width: calc(100% - 40px);
				}
			}
		}
	}

	@include max-down(750px) {
		&.filter-program,
		&.filter-plo,
		.page-template-page-hub-academics &,
		.page-template-page-hub-academics-degree-type &,
		.page-template-page-hub-academics-location & {
			.filter-list {
				ul {
					columns: 2;
				}
			}
		}
	}

	@include max-down(500px) {
		&.filter-program,
		&.filter-plo,
		.page-template-page-hub-academics &,
		.page-template-page-hub-academics-degree-type &,
		.page-template-page-hub-academics-location & {
			.filters {
				.filter {
					width: 50%;
					margin-right: 0;
				}

				.clear-filters {
					display: block;
					margin-top: 5px;
				}
			}

			.active-filters {
				padding-bottom: 0;
			}

			.filter-list {
				ul {
					columns: 1;
				}
			}
		}
	}
}