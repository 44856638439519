.page-template-page-general-template-1 {
	.contact-bar-wrap {
		position: relative;
		padding-top: 70px;
		padding-bottom: 175px;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -95px;
			left: -210px;
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gray.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(1000px) {
			padding-top: 0;

			.plus-pattern {
				display: none;
			}
		}

		@include max-down(600px) {
			padding-bottom: 100px;
		}
	}
}