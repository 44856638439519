.page-template-page-general-plan-a-visit {
	.cu-section__main-content {
		.diamond-pattern {
			width: 470px;
			height: 620px;
			bottom: -75px;
			left: 30px;
			background: url('/wp-content/themes/cornerstone-2024/svg/diamond-pattern-gold-5-column.svg') center no-repeat;
			background-size: contain;
		}
	}

	.half-sections-wrap {
		position: relative;
		z-index: 1;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: calc(100% - 40px);
			position: absolute;
			top: 0;
			left: 0;
			background: $blue;
		}

		.cu-section__half.has-pattern {
			.triangle-pattern {
				display: block;
				width: 353px;
				height: 491px;
				position: absolute;
				top: -95px;
				left: 0;
				z-index: 1;
				opacity: 0.13;
				background: url('/wp-content/themes/cornerstone-2024/svg/triangle-pattern-gray-wide.svg') center no-repeat;
				background-size: contain;
			}

			.image-3 {
				z-index: 2;
			}

			.inner-wrap {
				z-index: 3;
			}
		}

		@include max-down(1160px) {
			&:before {
				height: 100%;
			}
		}

		@include max-down(1000px) {
			padding-bottom: 100px;

			.cu-section__half.has-pattern .triangle-pattern {
				display: none;
			}

			.cu-section__half:nth-child(2n) {
				.inner-wrap .images {
					.image-1 {
						margin: 0 0 0 auto;
					}

					.image-2 {
						right: auto;
						left: 0;
					}
				}
			}
		}
	}

	.page-background-wrap {
		padding-bottom: 425px;

		@include max-down(750px) {
			padding-bottom: 100px;
		}

		@include max-down(600px) {
			padding-bottom: 175px;
		}
	}

	.testimonial-wrap {
		position: relative;
		margin-top: -350px;

		.triangle-pattern {
			display: block;
			width: 383px;
			height: 793px;
			position: absolute;
			bottom: -195px;
			right: 0;
			background: url('/wp-content/themes/cornerstone-2024/svg/triangle-pattern-gold-spread.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(1000px) {
			.triangle-pattern {
				display: none;
			}
		}

		@include max-down(750px) {
			margin-top: -150px;
		}
	}

	.cu-section__faculty-list {
		position: relative;
		z-index: 2;
	}

	.contact-bar-wrap {
		position: relative;
		padding-top: 110px;
		padding-bottom: 175px;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -95px;
			left: -210px;
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gray.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(1000px) {
			padding-top: 0;
		}

		@include max-down(600px) {
			padding-bottom: 100px;

			.plus-pattern {
				display: none;
			}
		}
	}
}