@font-face {
    font-family: 'just_wrightregular';
    src: url('/wp-content/themes/cornerstone-2024/webfonts/just_wright-webfont.woff2') format('woff2'),
         url('../webfonts/just_wright-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

$n_font: 'acumin-pro', Helvetica, Arial, sans-serif;
$c_font: 'acumin-pro-condensed', Helvetica, Arial, sans-serif;
$ec_font: 'acumin-pro-extra-condensed', Helvetica, Arial, sans-serif;
$sc_font: 'acumin-pro-semi-condensed', Helvetica, Arial, sans-serif;
$s_font: 'just_wrightregular', Helvetica, Arial, sans-serif;

html,
body {
	font-family: $n_font;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
	margin-bottom: 0.55em;
	font-family: $ec_font;

	&.no-m {
		margin-bottom: 0;
	}
}

sup {
	display: inline-block;
	vertical-align: top;
	font-size: 0.6em;
	line-height: 1em;
}

.headline {
	font-family: $ec_font;
	font-size: 54px;
	font-weight: 800;
	line-height: 0.92em;
	letter-spacing: 0.18em;
	text-transform: uppercase;
	color: $blue;

	&.alt {
		text-transform: none;
		font-family: $sc_font;
		font-size: 28px;
		font-weight: 900;
		letter-spacing: 0;

		span {
			display: block;
			margin-bottom: 10px;

			font-family: $c_font;
			font-size: 0.777rem;
			line-height: 1em;
			font-weight: 700;
			letter-spacing: 0.06em;
			text-transform: uppercase;
		}
	}

	&.hero {
		font-size: 80px;
		line-height: 72px;
		letter-spacing: 0.04em;

		span {
			display: block;
			font-size: 32px;
			line-height: 44px;
			font-weight: 600;
			letter-spacing: 0.14em;
		}
	}

	&.home-hero {
		font-size: 136px;
		line-height: 112px;
		letter-spacing: 0.04em;
		font-weight: 700;

		span {
			display: block;
			font-size: 64px;
			line-height: 76px;
			font-weight: 700;
			letter-spacing: 0.14em;
		}
	}

	&.large {
		font-size: 48px;
		letter-spacing: 0.12em;
	}

	&.medium {
		font-size: 40px;
	}

	&.small {
		font-size: 32px;
		line-height: 1.125em;
		letter-spacing: 0.12em;
	}

	&.xsmall {
		font-size: 24px;
		line-height: 1.2em;
		letter-spacing: 0.12em;
	}

	&.cxsmall {
		font-family: $c_font;
		font-size: 20px;
		line-height: 1.2em;
		letter-spacing: 0.066em;
	}

	&.text {
		font-family: $sc_font;
		font-size: 0.875rem;
		line-height: 1.375em;
		letter-spacing: 0.02em;
	}

	&.white {
		color: $white;
	}

	&.gold {
		color: $gold;
	}

	&.vertical-line-before {
		&:before {
			content: '';
			display: block;
			width: 2px;
			height: 60px;
			background: $gold;
			margin-bottom: 30px;
		}
	}

	&.horizontal-line-before {
		&:before {
			content: '';
			display: block;
			width: 30px;
			height: 2px;
			background: $gold;
			margin-bottom: 15px;
		}

		&.line-before-blue {
			&:before {
				background: $blue;
			}
		}
	}

	&.centered {
		text-align: center;

		&:before {
			margin-left: auto;
			margin-right: auto;
		}
	}

	@include max-down(1600px) {
		font-size: 48px;

		&.hero {
			font-size: 72px;
			line-height: 68px;
		}
	}

	@include max-down(1300px) {
		&.hero {
			font-size: 64px;
			line-height: 60px;
		}
	}

	@include max-down(1225px) {
		&.home-hero {
			font-size: 100px;
			line-height: 92px;

			span {
				font-size: 54px;
				line-height: 62px;
			}
		}
	}

	@include max-down(750px) {
		&.home-hero {
			font-size: 48px;
			line-height: 43px;

			span {
				font-size: 27px;
				line-height: 34px;
			}
		}
	}

	@include max-down(600px) {
		font-size: 42px;

		&.alt {
			font-size: 24px;
			line-height: 1.1;
		}

		&.hero {
			font-size: 42px;
			line-height: 40px;

			span {
				font-size: 24px;
				line-height: 28px;
			}
		}

		&.large {
			font-size: 38px;
		}

		&.medium {
			font-size: 34px;
		}

		&.small {
			font-size: 26px;
		}

		&.xsmall {
			font-size: 22px;
		}

		&.vertical-line-before {
			&:before {
				height: 40px;
				margin-bottom: 20px;
			}
		}
	}
}

.alt-headline {
	font-family: $sc_font;
	font-size: 28px;
	font-weight: 800;
	color: $blue;

	&.white {
		color: $white;
	}

	&.gold {
		color: $gold;
	}

	&.small {
		font-size: 24px;
	}

	&.horizontal-line-before {
		&:before {
			content: '';
			display: block;
			width: 30px;
			height: 2px;
			background: $gold;
			margin-bottom: 15px;
		}

		&.line-before-blue {
			&:before {
				background: $blue;
			}
		}
	}

	@include max-down(1160px) {
		font-size: 24px;
	}

	@include max-down(600px) {
		font-size: 22px;

		&.small {
			font-size: 18px;
		}
	}
}

.copy {
	color: $black;

	h3,
	h4,
	h5,
	h6 {
		font-size: 28px;
		font-weight: 600;
		letter-spacing: 0.18em;
		text-transform: uppercase;
		color: $blue;
	}

	h4 {
		font-size: 22px;
	}

	h5 {
		font-size: 18px;
	}

	h6 {
		font-family: $sc_font;
		font-size: 18px;
		font-weight: 800;
		color: $blue;
	}

	p {
		margin-bottom: 2em;
		font-size: 1rem;
		line-height: 1.8em;
		letter-spacing: 0.01em;

		&:last-child {
			margin-bottom: 0;
		}

		a {
			color: inherit;
			font-weight: 700;

			@include non-touch {
				&:not(.btn):hover {
					color: $gold;
				}
			}
		}
	}

	p.post-button {
		margin: 15px 0;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	p.pullquote {
		width: 150%;
		position: relative;
		margin: 75px -25%;
		padding: 40px 0;

		font-family: $sc_font;
		font-size: 2rem;
		line-height: 1.5em;
		font-weight: 800;
		color: $blue;
		text-align: center;

		&:before,
		&:after {
			content: '';
			display: block;
			width: 60px;
			height: 2px;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			background: $gold;
		}

		&:after {
			top: auto;
			bottom: 0;
		}

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		@include max-down(1300px) {
			width: 120%;
			margin-left: -10%;
			margin-right: -10%;
		}

		@include max-down(1000px) {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
		}

		@include max-down(600px) {
			margin-top: 55px;
			margin-bottom: 55px;
			font-size: 1.6rem;
		}
	}

	.post-video {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;

		iframe {
			width: 100% !important;
			height: 100% !important;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	p.post-image {
		width: 168%;
		position: relative;
		margin: 75px -34%;

		picture {
			display: block;
			width: 100%;
			height: auto;
			position: relative;
			z-index: 1;
		}

		button.tbx-module__video-overlay-toggle {
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;

			&:before {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				background: rgba($black, 0.3);
			}

			span {
				position: relative;
				z-index: 2;
			}
		}

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		@include max-down(1000px) {
			width: calc(100% + 10vw);
			margin-left: -5vw;
			margin-right: -5vw;
		}

		@include max-down(600px) {
			margin-top: 55px;
			margin-bottom: 55px;
		}
	}

	strong {
		font-weight: bold;
	}

	em {
		font-style: italic;
	}

	ul {
		list-style: square;
		margin: 2em 0;
		padding-left: 25px;

		li {
			font-size: 1rem;
			line-height: 1.8em;
			margin-bottom: 1em;

			&::marker {
				color: $gold;
			}

			a {
				color: inherit;
				font-weight: 700;

				@include non-touch {
					&:hover {
						color: $gold;
					}
				}
			}
		}

		ul {
			margin-top: 1em;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
	
	ol {
		list-style: decimal;
		margin: 2em 0;
		padding-left: 25px;

		li {
			font-size: 1rem;
			line-height: 1.8em;
			margin-bottom: 1em;

			a {
				color: inherit;
				font-weight: 700;

				@include non-touch {
					&:hover {
						color: $gold;
					}
				}
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	iframe {
		display: block;
		margin: 0 auto;
	}

	.tbx-shortcode-button-wrap {
		margin: 1em 0;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.tablepress {
		margin: 2em 0;

		thead th {
			padding-top: 15px;
			padding-bottom: 15px;
			background-color: $altgray;

			font-family: $ec_font;
			font-size: 1.3rem;
			font-weight: 600;
			letter-spacing: 0.05em;
		}

		.odd td {
			background-color: $lgray;
		}

		tbody {
			td {
				font-size: 1rem;
				line-height: 1.8em;
				letter-spacing: 0.01em;
			}
		}
	}

	&.white {
		color: $white;

		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}
	}
}