.page-template-page-calendar-template,
.tribe-events-page-template {
	.expanders-wrap {
		padding-bottom: 125px;
		
		.cu-part__expanders {
			width: 1170px;
			position: relative;
			z-index: 2;
			margin: 0 auto;
		}

		@include max-down(1300px) {
			.cu-part__expanders {
				width: 90%;
			}
		}

		@include max-down(600px) {
			padding-bottom: 75px;

			.cu-part__expanders {
				width: calc(100% - 40px);
			}
		}
	}

	.calendar-wrap {
		width: 1315px;
		margin: 0 auto 150px;

		@include max-down(1450px) {
			width: 90%;
		}

		.tribe-events {
			.tribe-common-l-container {
				max-width: 100%;
				padding: 0;
			}


			.tribe-events-header {

				.tribe-events-c-events-bar {
					padding: 12px 0;
					border: 1px solid $offgray;

					@include max-down(853px) {
						border: 0;
						padding: 0;
					}

					&__search-button {
						background: none;
						border: 0;
						border-radius: 0;
						padding: 0;
						margin-right: 15px;
						
						svg path {
							fill: $blue;
						}
					}

					.tribe-events-c-search__input-control {
						margin: 0;
					}

					.tribe-events-c-search__input {
						height: 45px;
						border: 0;
						padding: 0 15px 0 50px;
						padding-left: 50px;
						background: url('/wp-content/themes/cornerstone-2024/svg/search-icon-gold.svg') left 18px center no-repeat;
						background-size: 15px 16px;

						font-family: $c_font;
						font-size: 18px;
						font-weight: 600;
						letter-spacing: 0.04em;
						text-transform: uppercase;
						color: $blue;

						&:placeholder {
							color: $blue;
							opacity: 1;
						}

						&::placeholder {
							color: $blue;
							opacity: 1;
						}
					}

					.tribe-events-c-search__button {
						background: $gold;
					}
				}

				.tribe-events-c-events-bar__filter-button-container {
					margin: 0;
					align-items: center;

					.tribe-events-c-events-bar__filter-button {
						margin: 0;
						background: none;
						border: 0;
						padding: 10px 30px;

						font-family: $c_font;
						font-size: 18px;
						font-weight: 600;
						letter-spacing: 0.04em;
						text-transform: uppercase;
						color: $blue;

						svg path {
							fill: $blue;
						}

						@include max-down(853px) {
							padding: 0;
							margin-right: 15px;
						}
					}
				}

				.tribe-events-c-events-bar__views {
					border-left: 1px solid $offgray;

					@include max-down(853px) {
						border-left: 0;
					}
					
					.tribe-events-c-view-selector--labels {
						.tribe-events-c-view-selector__button {
							border: 0;
							background: none;
							padding: 0;

							svg path {
								fill: $blue;
							}
						}

						.tribe-events-c-view-selector__list-item-link {
							padding: 0;

							font-family: $c_font;
							font-size: 18px;
							font-weight: 600;
							letter-spacing: 0.04em;
							text-transform: uppercase;
							text-decoration: none;
							color: $blue;
						}
					}
				}

				.tribe-filter-bar__filters-slider-container {
					width: 100%;

					.tribe-filter-bar__filters-slide {
						width: auto;

						.tribe-filter-bar-c-pill {
							&__pill {
								border: 1px solid $offgray;

								font-family: $c_font;
								font-size: 14px;
								font-weight: 600;
								letter-spacing: 0.04em;
								text-transform: uppercase;
								text-decoration: none;
								color: $gray;
							}
						}
					}
				}

				.tribe-events-c-top-bar {
					&__nav {
						button {
							border: 0;
							box-shadow: none;
							background: none;
						}

						a.tribe-common-c-btn-icon {
							color: $gray;
						}
					}

					&__today-button {
						border: 1px solid $offgray;
						padding: 8px 12px;

						font-family: $c_font;
						font-size: 14px;
						font-weight: 600;
						letter-spacing: 0.04em;
						text-transform: uppercase;
						text-decoration: none;
						color: $gray;
					}

					&__datepicker-button {
						font-family: $sc_font;
						font-size: 36px;
						font-weight: 900;
						line-height: 34px;
						color: $blue;
					}
				}
			}

			.tribe-events-calendar-month {
				&__header-column {
					padding-bottom: 10px;
				}

				&__header-column-title {
					font-family: $sc_font;
					font-size: 19px;
					font-weight: 700;
					line-height: 1em;
					color: $gray;
					text-transform: uppercase;

					@include max-down(853px) {
						text-align: center;
					}
				}

				&__day-cell {
					background: none;
					border: 0;
				}

				&__day-date-daynum {
					font-family: $ec_font;
					font-size: 49px;
					font-weight: 300;
					color: $gray;

					a {
						color: inherit;
						text-decoration: none;
					}

					@include max-down(853px) {
						font-size: 20px;
						font-weight: 600;
					}
				}

				&__mobile-events-icon {
					background: $gold;
				}

				&__multiday-event-wrapper {
					height: auto;
				}

				&__multiday-event-bar {
					background: $blue;

					&-inner {
						padding: 2px 16px 5px;
					}
				}

				&__multiday-event-bar-title {
					font-family: $ec_font;
					font-size: 21px;
					font-weight: 600;
					line-height: 1em;
					text-transform: uppercase;
					color: $white;
				}

				&__calendar-event-datetime {
					font-family: $sc_font;
					font-size: 16px;
					font-weight: 700;
					line-height: 1em;
					color: $gray;
					text-transform: uppercase;
				}

				&__calendar-event-title {
					margin-top: 5px;

					font-family: $ec_font;
					font-size: 21px;
					font-weight: 600;
					line-height: 1em;
					text-transform: uppercase;
					color: $blue;

					a {
						color: inherit;
						text-decoration: none;
					}
				}

				&-mobile-events {
					&__day-marker {
						font-family: $ec_font;
						font-size: 24px;
						font-weight: 600;
						line-height: 1em;
						text-transform: uppercase;
						color: $blue;
					}

					&__mobile-event-datetime {
						font-family: $sc_font;
						font-size: 16px;
						font-weight: 700;
						line-height: 1em;
						color: $gold;
						text-transform: uppercase;
					}

					&__mobile-event-title {
						font-family: $ec_font;
						font-size: 28px;
						font-weight: 600;
						line-height: 1em;
						text-transform: uppercase;
						color: $blue;

						a {
							color: inherit;
							text-decoration: none;
						}
					}
				}
			}

			.tribe-events-calendar-list {
				&__month-separator {
					position: relative;

					&:after {
						content: '';
						display: block;
						width: 100%;
						height: 1px;
						position: absolute;
						top: 50%;
						left: 0;
						background: $altgray;
					}
				}

				&__month-separator-text {
					display: inline-block;
					position: relative;
					z-index: 2;
					padding-right: 20px;
					background: $white;

					font-family: $sc_font;
					font-size: 16px;
					font-weight: 700;
					line-height: 1em;
					color: $gray;
					text-transform: uppercase;
				}

				&__event-date-tag-weekday {
					font-family: $sc_font;
					font-size: 12px;
					font-weight: 700;
					line-height: 1em;
					letter-spacing: 0.02em;
					color: $gray;
					text-transform: uppercase;
				}

				&__event-date-tag-daynum {
					font-family: $ec_font;
					font-size: 56px;
					font-weight: 300;
					line-height: 1em;
					text-transform: uppercase;
					color: $gray;
				}

				&__event-datetime-wrapper {
					font-family: $sc_font;
					font-size: 16px;
					font-weight: 700;
					line-height: 1em;
					color: $gray;
					text-transform: uppercase;
				}

				&__event-title {
					font-family: $ec_font;
					font-size: 32px;
					font-weight: 600;
					line-height: 1em;
					letter-spacing: 0.1em;
					text-transform: uppercase;
					color: $blue;

					a {
						color: inherit;
						text-decoration: none;
					}
				}

				&__event-venue,
				&__event-cost {
					font-family: $sc_font;
					font-size: 16px;
					font-weight: 700;
					line-height: 1em;
					color: $gold;
					text-transform: uppercase;
				}

				&__event-description {
					font-family: $n_font;
					font-size: 18px;
					line-height: 1.8em;
					letter-spacing: 0.01em;
				}

				&__event-cost {
					font-size: 20px;
				}
			}

			.tribe-events-c-nav {
				border-top: 1px solid $offgray;
				padding-bottom: 15px;

				&__prev,
				&__next,
				&__today {
					background: none;
					padding: 0;
					border: 0;
					border-radius: 0;

					font-family: $c_font;
					font-size: 18px;
					font-weight: 600;
					letter-spacing: 0.04em;
					text-transform: uppercase;
					text-decoration: none;
					color: $blue;

					&[disabled] {
						pointer-events: none;
						opacity: 0.7;
					}
				}

				&__next {
					position: relative;
					padding-right: 27px;

					&:after {
						content: '';
						display: block;
						width: 12px;
						height: 10px;
						position: absolute;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
						background: url('/wp-content/themes/cornerstone-2024/svg/button-arrow-blue.svg') center no-repeat;
						background-size: contain;
					}
				}

				&__prev {
					position: relative;
					padding-left: 27px;

					&:before {
						content: '';
						display: block;
						width: 12px;
						height: 10px;
						position: absolute;
						top: 50%;
						left: 0;
						transform: translateY(-50%) rotate(180deg);
						background: url('/wp-content/themes/cornerstone-2024/svg/button-arrow-blue.svg') center no-repeat;
						background-size: contain;
					}
				}
			}

			.tribe-events-c-ical {
				&__link {
					border: 2px solid $gold;

					font-family: $c_font;
					font-size: 18px;
					font-weight: 600;
					letter-spacing: 0.08em;
					text-transform: uppercase;
					color: $blue;
					text-decoration: none;

					@include non-touch {
						&:hover {
							background: $gold;
						}
					}
				}
			}

			.tribe-events-promo {
				display: none;
			}
		}
	}

	.page-background-wrap {
		padding-bottom: 280px;

		@include max-down(650px) {
			padding-bottom: 30px;
		}
	}
}

.tooltipster-base .tooltipster-box {
	padding: 20px;
	background: $white;
}

.tribe-events-calendar-month__calendar-event-tooltip {
	width: auto;
	max-width: 250px;
	background: $white;
	overflow: hidden;

	&-datetime,
	&-cost {
		font-family: $sc_font;
		font-size: 16px;
		font-weight: 700;
		line-height: 1em;
		color: $gray;
		text-transform: uppercase;
	}

	&-title {
		margin: 10px 0;

		font-family: $ec_font;
		font-size: 21px;
		font-weight: 600;
		line-height: 1em;
		text-transform: uppercase;
		color: $blue;

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	&-description {
		font-family: $n_font;
		font-size: 12px;
		color: $black;
	}
}

.single-tribe_events {
	#tribe-events-pg-template {
		max-width: 100%;
		padding: 0;
	}

	.tribe-events-single {
		position: relative;

		.event-title-wrap {
			background: rgba($offgray, 0.15);
			padding-bottom: 225px;
		}

		.tribe-events-back {
			width: 90%;
			position: relative;
			z-index: 2;
			margin: 0 auto;
			padding: 35px 0;

			a {
				background: none;
				padding: 0;
				border: 0;
				border-radius: 0;

				font-family: $c_font;
				font-size: 18px;
				font-weight: 600;
				letter-spacing: 0.04em;
				text-transform: uppercase;
				text-decoration: none;
				color: $blue;
			}
		}

		.tribe-events-notices {
			width: 90%;
			position: relative;
			z-index: 2;
			margin: 0 auto 35px;
			padding: 0;
			border-bottom: 0;
		}

		.tribe-events.tribe-common {
			width: 90%!important;
			max-width: 875px;
			position: relative;
			z-index: 2;
			margin: 0 auto;
			.tribe-events-c-subscribe-dropdown__button {
				width: 240px;
			}
			svg {
				max-width: 29px;
				margin-top: 4px;
			}
		}

		.tribe-events-single-event-title {
			width: 90%;
			margin: 0 auto;
			position: relative;
			z-index: 2;

			font-family: $ec_font;
			font-size: 54px;
			font-weight: 600;
			letter-spacing: 0.18em;
			text-transform: uppercase;
			line-height: 1.2em;
			color: $blue;
			text-align: center;

			&:before {
				content: '';
				display: block;
				width: 2px;
				height: 60px;
				background: $gold;
				margin: 0 auto 20px;
			}
		}

		.tribe-events-schedule {
			display: block;
			width: 90%;
			margin: 15px auto 0;
			position: relative;
			z-index: 2;

			h2 {
				display: block;
				margin-bottom: 0;
				margin-right: 0;

				font-family: $ec_font;
				font-size: 27px;
				font-weight: 600;
				letter-spacing: 0.07em;
				color: $blue;
				text-transform: uppercase;
				text-align: center;
			}

			.tribe-events-cost {
				display: block;
				margin-top: 15px;

				font-family: $ec_font;
				font-size: 27px;
				font-weight: 600;
				letter-spacing: 0.07em;
				color: $gold;
				text-transform: uppercase;
				text-align: center;
			}

			.button-wrap {
				margin-top: 35px;
				text-align: center;
			}
		}

		> .tribe_events {
			display: block;
			z-index: 2;
			overflow: visible;
		}

		.tribe-events-event-image {
			width: 100%;
			max-width: 1470px;
			position: relative;
			z-index: 2;
			margin: -110px auto 0;

			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}

		.tribe-events-single-event-description {
			width: 90% !important;
			max-width: 875px;
			position: relative;
			z-index: 2;
			margin: 110px auto 0;

			p {
				margin-bottom: 2em;
				font-size: 1rem;
				line-height: 1.8em;
				letter-spacing: 0.01em;

				&:last-child {
					margin-bottom: 0;
				}

				a {
					color: inherit;
					font-weight: 700;

					@include non-touch {
						&:not(.btn):hover {
							color: $gold;
						}
					}
				}
			}
		}

		.tribe-events-cal-links {
			display: block;
			width: 90%;
			margin: 100px auto 0;

			text-align: center;

			a {
				display: inline-block;
				vertical-align: middle;
				width: 280px;
				border: 2px solid $gold;
				padding: 0;

				font-family: $c_font;
				font-size: 18px;
				font-weight: 600;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				color: $blue;
				line-height: 44px;
				text-decoration: none;

				&:last-child {
					margin-left: 30px;
				}
			}

			@include max-down(769px) {
				a {
					display: block;
					margin: 0 auto;

					&:last-child {
						margin-left: auto;
						margin-top: 15px;
					}
				}
			}
		}

		.details-section {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 20px;
			width: 1020px;
			margin: 100px auto 0;

			&:before,
			&:after {
				display: none;
			}

			.tribe-events-meta-group-details,
			.tribe-events-meta-group-organizer {
				width: 100%;
				margin: 0;
				background: $blue;
				padding: 50px 50px 30px 50px;

				.tribe-events-single-section-title {
					font-family: $sc_font;
					font-size: 28px;
					font-weight: 900;
					color: $white;

					&:before {
						content: '';
						display: block;
						width: 30px;
						height: 2px;
						background: $gold;
						margin-bottom: 20px;
					}
				}

				dt {
					width: 145px;
					float: left;
					margin: 0 0 20px 0 !important;

					font-family: $c_font;
					font-size: 18px;
					line-height: 1em;
					font-weight: 600;
					letter-spacing: 0.08em;
					text-transform: uppercase;
					color: $gold;
				}

				dd {
					width: calc(100% - 145px);
					float: left;
					margin: 0 0 20px 0 !important;

					font-family: $c_font;
					font-size: 18px;
					line-height: 1em;
					font-weight: 700;
					letter-spacing: 0.08em;
					text-transform: uppercase;
					color: $white;

					a {
						color: inherit;
						text-decoration: none;
					}

					&.tribe-organizer {
						width: 100%;
						font-family: $ec_font;
						font-size: 30px;
						font-weight: 600;
						letter-spacing: 0.18em;
					}
				}
			}

			.tribe-events-meta-group-organizer {
				box-shadow: 6px 6px 24px rgba($black, 0.25);
				background: $white;

				.tribe-events-single-section-title {
					color: $blue;
				}

				dd {
					color: $blue;
				}
			}

			@include max-down(1160px) {
				width: 90%;

				.tribe-events-meta-group-details,
				.tribe-events-meta-group-organizer {
					dt, dd {
						width: 100%;
						float: none;
					}

					dt {
						margin-bottom: 5px;
					}
				}
			}

			@include max-down(769px) {
				grid-template-columns: 1fr;
				row-gap: 20px;
			}
		}

		.venue-section {
			display: grid;
			grid-template-columns: 535px 1fr;
			width: 1175px;
			margin: 100px auto 0;
			align-items: center;

			&:before,
			&:after {
				display: none;
			}

			> div {
				width: 100%;
			}

			.tribe-events-single-section-title {
				font-family: $sc_font;
				font-size: 28px;
				font-weight: 900;
				color: $blue;

				&:before {
					content: '';
					display: block;
					width: 30px;
					height: 2px;
					background: $gold;
					margin-bottom: 20px;
				}
			}

			.tribe-events-venue-map {
				width: 100%;
				height: 515px;

				iframe {
					width: 100%;
					height: 100%;
					max-height: 100%;
				}
			}

			.tribe-venue {
				font-family: $ec_font;
				font-size: 40px;
				font-weight: 600;
				letter-spacing: 0.18em;
				color: $blue;
				text-transform: uppercase;

				a {
					color: inherit;
					text-decoration: none;
				}
			}

			.tribe-venue-location {
				margin-top: 15px;

				font-family: $sc_font;
				font-size: 18px;
				line-height: 28px;

				.tribe-address {
					display: block;
				}

				a {
					color: $gold;
				}
			}

			dt {
				margin-top: 15px;
				margin-bottom: 5px;
				font-family: $c_font;
				font-size: 18px;
				font-weight: 600;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				color: $blue;
			}

			dd {
				a {
					color: $gold;
				}
			}

			@include max-down(1300px) {
				width: 90%;
			}

			@include max-down(1160px) {
				grid-template-columns: 1fr 1fr;
			}

			@include max-down(769px) {
				grid-template-columns: 1fr;

				.tribe-events-venue-map {
					margin-top: 55px;
				}
			}
		}

		.tribe-events-related-events-title {
			width: 90%;
			margin: 100px auto 35px;

			font-family: $sc_font;
			font-size: 28px;
			font-weight: 900;
			color: $blue;

			&:before {
				content: '';
				display: block;
				width: 30px;
				height: 2px;
				background: $gold;
				margin-bottom: 20px;
			}
		}

		.tribe-related-events {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			column-gap: 20px;
			row-gap: 20px;
			width: 90%;
			margin: 0 auto;

			@include max-down(1160px) {
				grid-template-columns: 1fr 1fr;

				li:nth-child(3) {
					display: none;
				}
			}

			@include max-down(600px) {
				grid-template-columns: 1fr;
			}

			&:before,
			&:after {
				display: none;
			}

			li {
				display: block;
				width: 100%;
				height: 500px;
				background: $lgray;
				overflow: hidden;
				float: none;
				margin: 0;

				.tribe-related-events-thumbnail {
					width: 100%;
					height: 240px;
					position: relative;
					margin: 0;

					a {
						display: block;
						width: 100%;
						height: 100%;
						padding: 0;
					}

					img {
						display: block;
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						object-fit: cover;
					}
				}

				.tribe-related-event-info {
					display: block;
					width: 100%;
					height: calc(100% - 240px);
					position: relative;
					padding: 25px 30px 92px 30px;

					h3 {
						font-family: $ec_font;
						font-size: 24px;
						line-height: 1.2em;
						letter-spacing: 0.12em;
						font-weight: 600;
						text-transform: uppercase;
						color: $blue;

						a {
							color: inherit;
							text-decoration: none;
						}
					}

					span {
						display: inline-block;
						margin-bottom: 15px;
						font-family: $sc_font;
						font-size: 0.92rem;
						font-weight: 700;
						text-transform: uppercase;
						letter-spacing: 0.02em;
						color: $gold;
					}
				}
			}
		}

		.tribe-events-nav-pagination {
			width: 90%;
			margin: 0 auto;

			.tribe-events-nav-previous,
			.tribe-events-nav-next {
				a {
					background: none;
					padding: 0;
					border: 0;
					border-radius: 0;

					font-family: $c_font;
					font-size: 18px;
					font-weight: 600;
					letter-spacing: 0.04em;
					text-transform: uppercase;
					text-decoration: none;
					color: $blue;
				}
			}

			.tribe-events-nav-previous {
				text-align: left;
			}

			.tribe-events-nav-next {
				text-align: right;
			}
		}
	}

}