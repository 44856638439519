.page-template-page-general-embed {
	.embed-wrap {
		position: relative;
		padding: 120px 0;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -40px;
			left: -205px;
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;
		}

		.inner-wrap {
			width: 1445px;
			min-height: 800px;
			position: relative;
			z-index: 2;
			margin: 0 auto;
			overflow: auto;

			iframe {
				width: 100% !important;
			}
		}

		@include max-down(1600px) {
			.inner-wrap {
				width: 90%;
			}
		}

		@include max-down(1000px) {
			padding: 100px 0;

			.plus-pattern {
				display: none;
			}
		}

		@include max-down(600px) {
			padding: 75px 0;

			.inner-wrap {
				width: calc(100% - 40px);
			}
		}
	}
}