.cu-section__image-slider {
	width: 1445px;
	height: 680px;
	margin: 0 auto;

	.swiper-container {
		width: 100%;
		height: 100%;

		.swiper-button-prev,
		.swiper-button-next {
			width: 15px;
			height: 30px;
			margin-top: -7px;
			left: 15px;
			background: url('/wp-content/themes/cornerstone-2024/svg/slider-arrow-prev-white.svg') center no-repeat;
			background-size: contain;
		}

		.swiper-button-next {
			right: 15px;
			left: auto;
			background: url('/wp-content/themes/cornerstone-2024/svg/slider-arrow-next-white.svg') center no-repeat;
			background-size: contain;
		}

		.swiper-pagination {
			&-bullet {
				width: 12px;
				height: 12px;
				margin: 0 5px;
				background: none;
				opacity: 1;
				border: 2px solid $white;
				border-radius: 50%;
				box-shadow: 2px 2px 6px rgba($black, 0.35);

				&-active {
					background: $white;
				}
			}
		}
	}

	@include max-down(1600px) {
		width: 90%;
	}

	@include max-down(1200px) {
		height: 575px;
	}

	@include max-down(1000px) {
		height: 475px;
	}

	@include max-down(750px) {
		height: 400px;
	}

	@include max-down(600px) {
		height: 325px;
	}
}