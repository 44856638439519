.cu-section__half {
	position: relative;
	padding: 100px 0;

	.diamond-pattern {
		display: block;
		width: 460px;
		height: 585px;
		position: absolute;
		top: 0;
		left: 20px;
		z-index: 1;
		background: url('/wp-content/themes/cornerstone-2024/svg/diamond-pattern-gold-wide.svg') center no-repeat;
		background-size: contain;
	}

	.inner-wrap {
		width: 1400px;
		position: relative;
		z-index: 2;
		margin: 0 auto;

		.images {
			width: 655px;
			height: 535px;

			.image-1 {
				width: 575px;
				height: 100%;
				position: relative;
				margin: 0 0 0 auto;

				.image {
					width: 100%;
					height: 100%;
					position: relative;
					z-index: 1;
				}

				.tbx-module__video-overlay-toggle {
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;

					.icon {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}
		}

		.info {
			@include section-line-before(60px, 20px);
			width: 655px;
			position: absolute;
			top: 50%;
			right: 0;
			padding-left: 80px;
			transform: translateY(-50%);

			.button-wrap {
				margin-top: 35px;
				font-size: 0;

				span {
					display: inline-block;
					vertical-align: middle;
					margin-right: 20px;

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}

	&.no-top-padding {
		padding-top: 0;
	}

	&.no-bottom-padding {
		padding-bottom: 0;
	}

	&.two-images {
		padding-bottom: 170px;

		.images {
			position: relative;

			.image-2 {
				width: 275px;
				height: 280px;
				position: absolute;
				z-index: 2;

				.image {
					width: 100%;
					height: 100%;
					position: relative;
					z-index: 1;
				}
			}
		}

		&.image-top-left .images .image-2 {
			top: -70px;
			left: -70px;
		}

		&.image-bottom-left .images .image-2 {
			bottom: -70px;
			left: -70px;
		}

		&.image-bottom-right .images {
			.image-1 {
				margin: 0;
			}

			.image-2 {
				bottom: -70px;
				right: 0;
			}
		}

		&.image-right-middle-top {
			padding-bottom: 100px;

			.images {
				.image-1 {
					margin: 0;
				}

				.image-2 {
					top: 45px;
					right: 0;
				}
			}
		}

		&.image-right-middle-bottom {
			padding-bottom: 100px;

			.images {
				.image-1 {
					margin: 0;
				}

				.image-2 {
					bottom: 45px;
					right: 0;
				}
			}
		}

		&.no-bottom-padding {
			&.image-bottom-left,
			&.image-bottom-right, {
				padding-bottom: 70px;
			}

			&.image-right-middle-top,
			&.image-right-middle-bottom {
				padding-bottom: 0;
			}
		}
	}

	&.third-image {
		position: relative;
		padding-top: 140px;

		.image-3 {
			width: 540px;
			height: 493px;
			position: absolute;
			top: 10px;
			left: -35px;
			z-index: 1;

			.image {
				width: 100%;
				height: 100%;
				position: relative;
				z-index: 1;
			}
		}

		.inner-wrap {
			z-index: 2;
		}
	}

	&.long-text {
		.inner-wrap {
			@include clearfix;

			.images {
				float: right;
			}

			.info {
				margin-top: 95px;
				position: relative;
				float: left;
				transform: none;
			}
		}
	}

	&.flipped {
		.images {
			margin: 0 0 0 auto;
		}

		.info {
			right: auto;
			left: 0;
		}
	}

	@include max-down(1550px) {
		.inner-wrap {
			width: 90%;

			.info {
				width: calc(100% - 700px);
			}
		}

		&.two-images {
			&.image-top-left .images .image-2,
			&.image-bottom-left .images .image-2 {
				left: 0;
			}
		}
	}

	@include max-down(1260px) {
		.inner-wrap .info {
			@include section-line-before-break;
			padding-left: 0;
		}
	}

	@include max-down(1160px) {
		.inner-wrap {
			.images {
				width: 425px;
				height: 375px;

				.image-1 {
					width: 400px;
				}
			}

			.info {
				width: calc(100% - 475px);
			}
		}

		&.third-image {
			.image-3 {
				width: 425px;
				height: 388px;
			}
		}

		&.two-images {
			&.image-top-left .images .image-2,
			&.image-bottom-left .images .image-2,
			&.image-bottom-right .images .image-2,
			&.image-right-middle-top .images .image-2,
			&.image-right-middle-bottom .images .image-2 {
				width: 200px;
				height: 210px;
			}
		}
	}

	@include max-down(1000px) {
		.diamond-pattern {
			display: none;
		}
		
		.inner-wrap {
			width: 100%;

			.images {
				width: 80%;
				height: 440px;
				margin: 0 auto;

				.image-1 {
					width: 100%;
				}
			}

			.info {
				width: 80%;
				position: static;
				transform: none;
				margin: 55px auto 0 auto;
			}
		}

		&.third-image {
			padding-top: 100px;

			.image-3 {
				display: none;
			}

			&.no-top-padding {
				padding-top: 0;
			}
		}

		&.two-images {
			padding-bottom: 100px !important;

			&.image-top-left .images {
				width: 80%;
				height: 465px;
				padding-top: 30px;

				.image-1 {
					width: 80%;
					height: 440px;
				}

				.image-2 {
					top: 0;
				}
			}
			&.image-bottom-left .images,
			&.image-bottom-right .images {
				width: 80%;
				height: 465px;

				.image-1 {
					width: 80%;
					height: 440px;
				}

				.image-2 {
					bottom: 0;
				}
			}

			&.image-right-middle-top .images,
			&.image-right-middle-bottom .images {
				width: 80%;
				height: 440px;

				.image-1 {
					width: 80%;
					height: 440px;
				}
			}

			&.no-bottom-padding {
				padding-bottom: 0 !important;
			}
		}

		&.long-text {
			.inner-wrap {
				.images {
					float: none;
				}

				.info {
					float: none;
					margin-top: 55px;
				}
			}
		}
	}

	@include max-down(600px) {
		.inner-wrap {
			width: calc(100% - 40px);

			.images {
				width: 100%;
				height: auto;

				.image-1 {
					width: 100%;
					height: auto;
				}
			}

			.info {
				width: 100%;
			}
		}

		&.two-images {
			&.image-top-left .images,
			&.image-bottom-left .images,
			&.image-bottom-right .images,
			&.image-right-middle-top .images,
			&.image-right-middle-bottom .images {
				width: 100%;
				height: auto;

				.image-1 {
					width: 100%;
					height: auto;
				}

				.image-2 {
					display: none;
				}
			}
		}
	}

	@include max-down(375px) {
		.inner-wrap .info .button-wrap span {
			display: block;
			margin-right: 0;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}