.page-template-page-general-expand-collapse {
	.resources-wrap {
		width: 1170px;
		position: relative;
		z-index: 2;
		margin: 0 auto;
		padding: 120px 0 130px 0;

		@include max-down(1300px) {
			width: 90%;
		}

		@include max-down(1000px) {
			padding: 100px 0;
		}

		@include max-down(600px) {
			width: calc(100% - 40px);
			padding: 75px 0;
		}
	}

	.contact-bar-wrap {
		position: relative;
		padding-bottom: 245px;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -40px;
			left: -205px;
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gray.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(1000px) {
			padding-bottom: 100px;

			.plus-pattern {
				display: none;
			}
		}

		@include max-down(600px) {
			padding-bottom: 75px;
		}
	}
}