.tbx-module__video-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 0%;
	height: 0%;
	z-index: 100;
	opacity: 0;
	overflow: hidden;
	transform: scale(0.95);
	transition: opacity .3s 0s, width 0s .3s, height 0s .3s, transform .3s 0s;

	&.open {
		opacity: 1;
		width: 100%;
		height: 100%;
		transform: scale(1);
		transition: opacity .3s .01s, width 0s 0s, height 0s 0s, transform .3s .01s;
	}

	.video-overlay {
		position: fixed;
		overflow: hidden;
		top: 0;
		left: 0;
		width: 100%;
		height: 0%;
		background-color: rgba(0,0,0,0.8);
		z-index: 101;
		opacity: 0;
		transition: opacity .3s, height 0s .3s;

		&.open {
			height: 100%;
			opacity: 1;
			transition: opacity .3s .01s, height 0s;
		}

		.video-overlay-close {
			display: block;
			position: absolute;
			top: 25px;
			right: 25px;
			width: 25px;
			height: 25px;
			cursor: pointer;
			z-index: 102;
			background: url('/wp-content/themes/cornerstone-2024/svg/close.svg') center no-repeat;
			background-size: contain;
		}
	}
	.inner-content-wrap {
		width: 70%;
		margin: 0 auto;
		position: relative;
		z-index: 103;
		.inner {
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
			position: relative;
			iframe {
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 104;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.tbx-module__video-overlay .inner-content-wrap {
		width: 90%;
	}
}

@media screen and (max-width: 768px) {
	.tbx-module__video-overlay .inner-content-wrap {
		width: 80%;
	}
}