.cu-section__news-feed {
	position: relative;
	padding: 105px 0 75px;

	@include max-down(600px) {
		padding: 65px 0;
	}

	.plus-pattern {
		content: '';
		display: block;
		width: 494px;
		height: 537px;
		position: absolute;
		bottom: -175px;
		left: -165px;
		z-index: 1;
		background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-small-gold.svg') center no-repeat;
		background-size: contain;

		@include max-down(950px) {
			display: none;
		}
	}

	.news-feed-header {
		@include clearfix;
		width: 1470px;
		position: relative;
		z-index: 2;
		margin: 0 auto;

		&:before {
			content: '';
			display: block;
			width: 60px;
			height: 2px;
			position: absolute;
			top: 27px;
			left: -80px;
			background: $gold;
		}

		h2 {
			float: left;
		}

		.button-wrap {
			float: right;
			padding-top: 7px;
			font-size: 0;

			span {
				display: inline-block;
				vertical-align: middle;
				margin-right: 40px;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		@include max-down(1600px) {
			width: 90%;

			&:before {
				float: left;
				position: static;
				margin-top: 28px;
				margin-right: 20px;
			}
		}

		@include max-down(1200px) {
			.button-wrap {
				span {
					display: none;

					&:last-child {
						display: block;
					}
				}
			}
		}

		@include max-down(950px) {
			.button-wrap {
				display: none;
			}
		}

		@include max-down(750px) {
			width: calc(100% - 110px);

			&:before {
				width: 25px;
				float: none;
				position: absolute;
				left: -40px;
				margin-top: 0;
				margin-right: 0;
			}
		}

		@include max-down(600px) {
			width: 90%;
			
			&:before {
				position: static;
				width: 60px;
				margin-bottom: 15px;
			}
		}
	}

	.news-feed {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-column-gap: 25px;
		grid-row-gap: 25px;
		column-gap: 25px;
		row-gap: 25px;

		width: 1470px;
		position: relative;
		z-index: 2;
		margin: 65px auto 0 auto;

		&.no-grid {
			display: block;
		}

		.filter-list-item {
			background: $white;

			&.swiper-slide {
				width: 350px;

				.info {
					height: calc(100% - 240px) !important;
				}
			}

			&.featured.events {
				grid-column: 1 / span 2;
				background: $blue;

				a {
					@include clearfix;
				}

				.image {
					width: 425px;
					height: 100%;
					float: right;

					.category {
						display: none;
					}
				}

				.info {
					width: calc(100% - 425px);
					height: 100%;
					float: left;
					position: relative;

					.category {
						display: block;
						position: absolute;
						top: 30px;
						left: 30px;

						font-family: $sc_font;
						font-size: 0.66rem;
						font-weight: 700;
						text-transform: uppercase;
						letter-spacing: 0.02em;
						color: $offgray;
					}

					.inner-content-wrap {
						position: absolute;
						bottom: 30px;
						left: 30px;

						h3 {
							font-size: 36px;
							line-height: 0.94em;
							color: $white;
						}

						.button-wrap {
							.btn {
								color: $white;
							}
						}
					}
				}
			}

			&.featured.blog {
				.image {
					display: none;
				}

				.info {
					height: 100%;
					position: relative;

					.category {
						display: block;
						position: absolute;
						top: 30px;
						left: 30px;

						font-family: $sc_font;
						font-size: 0.66rem;
						font-weight: 700;
						text-transform: uppercase;
						letter-spacing: 0.02em;
						color: $offgray;
					}

					.inner-content-wrap {
						position: absolute;
						bottom: 30px;
						left: 30px;

						h3 {
							font-size: 36px;
							line-height: 0.94em;
						}
					}
				}
			}
		}

		.swiper-container {
			overflow: hidden;
		}

		.swiper-button-prev,
		.swiper-button-next {
			display: block;
			width: 72px;
			height: 72px;
			top: 220px;
			right: -36px;
			background: $dblue url('/wp-content/themes/cornerstone-2024/svg/slider-arrow-next-white.svg') center no-repeat;
			background-size: 13px 27px;

			&:after {
				display: none;
			}

			&.swiper-button-disabled {
				display: none;
			}
		}

		.swiper-button-prev {
			right: auto;
			left: -36px;
			background-image: url('/wp-content/themes/cornerstone-2024/svg/slider-arrow-prev-white.svg');
		}

		.swiper-scrollbar {
			width: 590px;
			height: 8px;
			margin: 55px auto 0;
			background: #E7EEEF;
			border-radius: 0;

			&-drag {
				background: $dblue;
				border-radius: 0;
				cursor: pointer;
			}
		}

		@include max-down(1600px) {
			width: 90%;

			.filter-list {
				&.featured.events {
					.image {
						width: 60%;
					}

					.info {
						width: 40%;
					}
				}
			}
		}

		@include max-down(1260px) {
			grid-template-columns: 1fr 1fr;

			.filter-list {
				&.featured.events {
					.image {
						height: 425px;
					}

					.info {
						height: 425px;
					}
				}
			}
		}

		@include max-down(600px) {
			grid-template-columns: 1fr;

			.filter-list {
				&.featured.events {
					grid-column: unset;

					a:after {
						display: none;
					}

					.image {
						float: none;
						width: 100%;
						height: 240px;

						.category {
							display: block;
						}
					}

					.info {
						float: none;
						width: 100%;
						height: calc(100% - 240px);

						.category {
							display: none;
						}

						.inner-content-wrap {
							position: static;
						}
					}
				}

				&.featured.blog {
					.image {
						display: block;
					}

					.info {
						.category {
							display: none;
						}

						.inner-content-wrap {
							position: static;

							h3 {
								font-size: 24px;
								line-height: 0.875em;
							}

							.button-wrap {
								display: none;
							}
						}
					}
				}
			}

			.filter-list-item {
				&.swiper-slide {
					width: calc(100% - 55px);
				}
			}

			.swiper-button-prev,
			.swiper-button-next {
				display: none;
			}
		}
	}

	> .button-wrap {
		display: none;
		width: 90%;
		position: relative;
		z-index: 2;
		margin: 45px auto 0;
		text-align: center;

		@include max-down(950px) {
			display: block;
		}
	}
}