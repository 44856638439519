html {
	scroll-behavior:smooth;
	body {
		background: #E4E4E4;
	}

	&.loading-overlay-shown,
	&.mega-nav-shown,
	&.mobile-menu-shown,
	&.overlay-menu-shown {
		overflow: hidden;

		body {
			overflow: hidden;
		}
	}

	&.alt-header {
		.header-wrap:not(.scrolled) {
			.logo {
				background: url('/wp-content/themes/cornerstone-2024/svg/full-logo-color.svg') center no-repeat;
				background-size: contain;

				@include max-down(1300px) {
					background: url('/wp-content/themes/cornerstone-2024/svg/full-logo-color-stacked.svg') center no-repeat;
					background-size: contain;
				}
				@include max-down(450px) {
					width: 55px;
					height: 57px;
					background-image: url('/wp-content/themes/cornerstone-2024/svg/logo-mark.svg');
				}
			}

			.header-right .main-nav li a {
				color: $blue;
			}

			.header-right .overlay-menu-toggle,
			.header-right .mobile-menu-toggle {
				.icon {
					span {
						background: $gold;
					}
				}

				.text {
					color: $blue;
				}
			}
		}

		.pseudo-body {
			padding-top: 137px;
		}

		@include max-down(1160px) {
			.pseudo-body {
				padding-top: 117px;
			}
		}

		@include max-down(450px) {
			.pseudo-body {
				padding-top: 108px;
			}
		}
	}

	&.alt-headercu24 {
		scroll-behavior:smooth;
		strong {
			font-weight:bold;
		}
		ul#menu-main-menu a {padding-left:20px; padding-right:20px;}
		.header {background-color:$blue;}
		.header-wrap {background-color:$blue;}
		.header-wrap:not(.scrolled) {
			.logo {
				background: url('/wp-content/themes/cornerstone-2024/svg/full-logo-white.svg') center no-repeat;
				background-size: contain;

				@include max-down(1400px) {
					background: url('/wp-content/themes/cornerstone-2024/svg/full-logo-white-stacked.svg') center no-repeat;
					background-size: contain;
				}
				@include max-down(450px) {
					width: 55px;
					height: 57px;
					background-image: url('/wp-content/themes/cornerstone-2024/svg/logo-mark-white.svg');
				}
			}
		}

		.pseudo-body {
			padding-top: 107px;
		}

		@include max-down(1160px) {
			.pseudo-body {
				padding-top: 107px;
			}
			.header-top {
				display:flex;
			}
		}

		@include max-down(450px) {
			.pseudo-body {
				padding-top: 108px;
			}
			.header-top {
				display:flex;
			}
		}
	}



}

.viewport {
	width: 1920px;
	margin: 0 auto;
	box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.2);
	background: #fff;
	//overflow: hidden;

	@include max-down(1920px) {
		width: 100%;
	}
}

.header-wrap {
	width: 100%;
	max-width: 1920px;
	position: absolute;
	top: 0;
	right: calc((100% - 1920px)/2);
	z-index: 20;
	background: none;
	transition: top .3s;

	&.scrolled {
		@include slideInDown($duration: 0.7s);
		position: fixed;
		top: 0;
		background: $blue;
		box-shadow: 2px 2px 12px rgba($black, 0.24);
	}

	.mobile-menu-shown & {
		background: none;

		&.scrolled {
			background: none !important;
		}
	}

	.header-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid $gray;

		.top-nav,
		.top-nav-right {
			font-size: 0;

			li {
				display: inline-block;
				vertical-align: middle;
				border-right: 1px solid $gray;

				&.item-btn {
					a {
						width: 160px;
						background: $gold;
						color: $dblue;
						text-align: center;
					}
				}
			}

			a {
				display: block;
				padding: 11px 13px 11px 13px;

				font-family: $sc_font;
				font-weight: 700;
				font-size: 14px;
				text-transform: uppercase;
				text-decoration: none;
				letter-spacing: 0.02em;
				color: $gray;

				@include non-touch {
					transition: color .3s;

					&:hover {
						color: $gold;
					}
				}
			}
		}

		.top-nav-right {
			li {
				border: 0;
				margin-right: 2px;

				&:first-child a {
					padding: 8px 13px;
					font-size: 19px;
					color: $ogray;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.logo {
			@include hidden-text;
			display: block;
			width: 322px;
			height: 48px;
			margin-left: 35px;
			background: url('/wp-content/themes/cornerstone-2024/svg/full-logo-white.svg') center no-repeat;
			background-size: contain;
		}

		.header-right {
			display: flex;
			align-items: center;
			padding-right: 35px;

			.main-nav {
				display: inline-block;
				vertical-align: middle;
				font-size: 0;

				li {
					display: inline-block;
					vertical-align: middle;

					a {
						display: block;
						padding: 25px 15px;

						font-family: $sc_font;
						font-weight: 700;
						font-size: 20px;
						text-decoration: none;
						letter-spacing: 0.02em;
						color: $white;

						&:before {
							content: '';
							display: inline-block;
							vertical-align: middle;
							width: 12px;
							height: 12px;
							margin-right: 10px;
							margin-top: -3px;
							background: url('/wp-content/themes/cornerstone-2024/svg/close-gold.svg') center no-repeat;
							background-size: contain;
							transform: rotate(45deg);
						}

						&.active {
							background: $white;
							color: $blue;

							&:before {
								transform: rotate(0deg);
							}
						}
					}
				}
			}

			.mobile-menu-phone {
				display: none;
				vertical-align: middle;

				a {
					font-family: $sc_font;
					font-size: 19px;
					font-weight: 700;
					letter-spacing: 0.02em;
					color: $dblue;
					text-decoration: none;

					@include non-touch {
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}

			.search-icon {
				display: inline-block;
				vertical-align: middle;
				margin-left: 20px;

				button {
					@include hidden-text;
					display: block;
					width: 30px;
					height: 30px;
					background: url('/wp-content/themes/cornerstone-2024/svg/search-icon-gold.svg') center no-repeat;
					background-size: 15px 16px;
				}
			}

			.mobile-menu-toggle {
				display: inline-block;
				vertical-align: middle;
				margin-left: 20px;
				font-size: 0;
				padding: 25px 15px;

				.icon {
					display: inline-block;
					vertical-align: middle;
					width: 30px;
					height: 20px;
					position: relative;
					margin-right: 20px;

					span {
						display: block;
						width: 100%;
						height: 2px;
						position: absolute;
						top: 0;
						right: 0;
						background: $white;
						transition: all .3s;

						&:nth-child(2) {
							width: 20px;
							top: 9px;
						}

						&:nth-child(3) {
							width: 25px;
							top: 18px;
						}
					}
				}

				.text {
					display: inline-block;
					vertical-align: middle;
					font-family: $sc_font;
					font-weight: 700;
					font-size: 1.125rem;
					text-transform: uppercase;
					text-decoration: none;
					letter-spacing: 0.02em;
					color: $white;
					transition: color .3s;

					&:before {
						content: 'Menu';
					}
				}

				&.active {
					background: $white;
					.icon {
						span {
							width: 100% !important;
							top: 50% !important;
							background: $gold;

							&:nth-child(1) {
								transform: rotate(45deg);
							}

							&:nth-child(2) {
								width: 0 !important;
							}

							&:nth-child(3) {
								transform: rotate(-45deg);
							}
						}
					}

					.text {
						color: $blue;
					}
				}

				@include non-touch {
					&:hover {
						.icon span {
							width: 100% !important;
						}
					}
				}
			}

			.mobile-menu-toggle {
				display: none;
				margin-left: 25px;

				.icon {
					margin-right: 0;
				}

				.text {
					display: none;
				}
			}
		}
	}

	@include max-down(1921px) {
		right: 0;
	}

	@include max-down(1450px) {
		.header {
			.logo {
				margin-left: 25px;
			}

			.header-right {
				padding-right: 15px;

				nav.main-nav li {
					margin-right: 0;
				}

				.search-icon {
					margin-left: 5px;
				}
			}
		}
	}

	@include max-down(1300px) {
		.header {
			.logo {
				width: 200px;
				height: 55px;
				background: url('/wp-content/themes/cornerstone-2024/svg/full-logo-white-stacked.svg') center no-repeat;
				background-size: contain;
			}
		}
	}

	@include max-down(1200px) {
		.header {
			.header-right {
				&:before {
					content: '';
					display: inline-block;
					vertical-align: middle;
					width: 0;
					height: 69px;
				}

				.main-nav {
					display: none;
				}

				.mobile-menu-toggle {
					display: inline-block;
				}
			}
		}
	}

	@include max-down(950px) {
		.header-top {
			.top-nav {
				display: none;
			}

			.top-nav-right {
				width: 100%;

				ul {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				li {
					display: block;
					width: 100%;
				}

				a {
					width: 100% !important;
					text-align: center;
					font-size: 15px !important;
				}
			}
		}
	}

	@include max-down(600px) {
		.header .header-right {
			.search-icon {
				margin-left: 0;
			}

			.overlay-menu-toggle,
			.mobile-menu-toggle {
				.icon {
					margin-right: 0;
				}

				.text {
					display: none;
				}
			}

			.mobile-menu-toggle {
				margin-left: 10px;
			}
		}
	}

	@include max-down(450px) {
		.header-top {
			.top-nav-right {
				li.item-btn a {
					padding: 11px 0;
				}
			}
		}

		.header {
			.logo {
				width: 55px;
				height: 57px;
				background-image: url('/wp-content/themes/cornerstone-2024/svg/logo-mark-white.svg');
			}
		}
	}
}