.program-template-post-template-program-ind-v2 {
	.page-background-wrap {
		padding-bottom: 100px;

		@include max-down(600px) {
			padding-bottom: 65px;
		}
	}

	.main-wrap {
		position: relative;
		padding-top: 85px;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			top: 50px;
			right: -105px;
			transform: rotate(-90deg);
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gray.svg') center no-repeat;
			background-size: contain;
		}

		.inner-wrap {
			@include clearfix;
			width: 1250px;
			position: relative;
			z-index: 2;
			margin: 0 auto;

			.info {
				@include section-line-before(60px, 20px);
				width: 700px;
				float: left;
				margin-top: 45px;
				padding-left: 80px;

				.button-wrap {
					margin-top: 35px;
					font-size: 0;

					> span {
						display: inline-block;
						vertical-align: middle;
						margin-right: 20px;

						&:last-child {
							margin-right: 0;
						}
					}
				}
			}

			.categories {
				width: 425px;
				float: right;
				margin: 0 0 0 auto;
				background: $blue;

				.inner-content-wrap {
					width: calc(100% - 100px);
					margin: 0 auto;
					padding: 50px 0;
				}

				.taxonomy {
					margin-bottom: 30px;

					.button-wrap {
						margin-bottom: 10px;

						&:last-child {
							margin-bottom: 0;
						}
					}

					&:last-child {
						margin-bottom: 0;
					}
				}

				h3 {
					margin-bottom: 10px;

					font-family: $c_font;
					font-size: 1rem;
					font-weight: 600;
					letter-spacing: 0.08em;
					text-transform: uppercase;
					color: $gold;

					.tooltip {
						display: inline-block;
						text-transform: none;
						
						a {
							color: $gold;
							text-decoration: none;
						}
					}
				}

				ul {
					li {
						margin-bottom: 10px;

						font-family: $c_font;
						font-size: 1rem;
						font-weight: 600;
						letter-spacing: 0.08em;
						text-transform: uppercase;
						color: $white;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		@include max-down(1400px) {
			.plus-pattern {
				right: -250px;
			}

			.inner-wrap {
				width: 90%;

				.info {
					width: 60%;
				}

				.categories {
					width: 35%;
				}
			}
		}

		@include max-down(1000px) {
			.plus-pattern {
				display: none;
			}

			.inner-wrap {
				.info {
					@include section-line-before-break;
					width: 100%;
					float: none;
					margin-top: 0;
					padding-left: 0;
				}

				.categories {
					width: 100%;
					float: none;
					margin-top: 55px;

					.inner-content-wrap {
						display: grid;
						grid-template-columns: 1fr 1fr;
						column-gap: 50px;
						row-gap: 30px;

						.taxonomy {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		@include max-down(600px) {
			.inner-wrap {
				width: calc(100% - 40px);

				.categories .inner-content-wrap {
					grid-template-columns: 1fr;
				}
			}
		}
	}

	.opportunities-wrap {
		position: relative;
		padding: 140px 0 0 0;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			top: 100px;
			right: -105px;
			transform: rotate(-90deg);
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;
		}

		.inner-wrap {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 35px;
			width: 1185px;
			position: relative;
			z-index: 2;
			margin: 0 auto;

			.careers,
			.internships {
				background: $blue;

				.inner-content-wrap {
					width: calc(100% - 100px);
					margin: 0 auto;
					padding: 50px 0;

					.copy ul li {
						margin-bottom: 0.05em;
					}
				}

				.copy ul {
					padding-left: 12px;

					li {
						margin-bottom: 0.5em;
						line-height: 1.2em;

						&::marker {
							font-size: 0.8em;
						}
					}
				}

				.opportunities {
					margin-top: 30px;

					h4 {
						margin-bottom: 10px;

						font-family: $c_font;
						font-size: 1rem;
						font-weight: 600;
						letter-spacing: 0.08em;
						text-transform: uppercase;
						color: $gold;
					}

					ul {
						columns: 2;

						li {
							margin-bottom: 10px;

							font-family: $c_font;
							font-size: 1rem;
							font-weight: 600;
							letter-spacing: 0.08em;
							text-transform: uppercase;
							color: $white;

							span {
								font-size: 10px;
								font-weight: 700;
								letter-spacing: 0.04em;
							}

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}

			.internships {
				background: $gold;

				.copy ul li::marker {
					color: $blue;
				}

				.opportunities h4 {
					color: $blue;
				}
			}
		}

		@include max-down(1550px) {
			.inner-wrap {
				width: 90%;
			}
		}

		@include max-down(1200px) {
			.plus-pattern {
				right: -250px;
			}
		}

		@include max-down(1000px) {
			padding: 100px 0 0 0;

			.plus-pattern {
				display: none;
			}

			.inner-wrap {
				grid-template-columns: 1fr;
				row-gap: 35px;
			}
		}

		@include max-down(750px) {
			padding: 75px 0 0 0;

			.inner-wrap {
				width: calc(100% - 40px);

				.careers,
				.internships {
					.opportunities ul {
						columns: 1;
					}
				}
			}
		}
	}

	.change-wrap {
		position: relative;
		padding: 165px 0;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			top: 100px;
			right: -105px;
			transform: rotate(-90deg);
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;
		}

		.diamond-pattern {
			display: block;
			width: 470px;
			height: 827px;
			position: absolute;
			bottom: -590px;
			left: -145px;
			background: url('/wp-content/themes/cornerstone-2024/svg/diamond-pattern-gold-5-column.svg') center no-repeat;
			background-size: contain;
		}

		.inner-wrap {
			@include clearfix;
			width: 1400px;
			position: relative;
			z-index: 2;
			margin: 0 auto;

			.info {
				@include section-line-before(60px, 20px);
				width: 655px;
				float: left;
				margin-top: 40px;
				padding-left: 80px;
			}

			.side {
				width: 575px;
				float: right;

				.careers,
				.internships {
					background: $blue;

					.inner-content-wrap {
						width: calc(100% - 100px);
						margin: 0 auto;
						padding: 50px 0;
					}

					.copy {
						ul {
							padding-left: 12px;

							li {
								margin-bottom: 0.5em;
								line-height: 1.2em;

								&::marker {
									font-size: 0.8em;
								}
							}
						}
					}
				}

				.internships {
					margin-top: 30px;
					background: $gold;

					.copy {
						p a {
							@include non-touch {
								&:hover {
									color: $blue;
								}
							}
						}

						ul li {
							&::marker {
								color: $blue;
							}
						}
					}

					.info-list {
						display: grid;
						grid-template-columns: 1fr 1fr;
						column-gap: 50px;
						row-gap: 35px;

						.info-item {
							h4 {
								margin-bottom: 10px;
								font-family: $c_font;
								font-weight: 600;
								letter-spacing: 0.08em;
								font-size: 1rem;
								color: $blue;
							}

							.copy {
								p {
									font-family: $c_font;
									font-size: 1rem;
									font-weight: 700;
									letter-spacing: 0.08em;
									line-height: 1em;
									text-transform: uppercase;
								}
							}
						}
					}
				}
			}
		}

		@include max-down(1550px) {
			.inner-wrap {
				width: 90%;

				.info {
					width: 50%;
				}

				.side {
					width: 45%;
				}
			}
		}

		@include max-down(1200px) {
			.plus-pattern {
				right: -250px;
			}

			.inner-wrap .info {
				@include section-line-before-break;
				padding-left: 0;
			}
		}

		@include max-down(1000px) {
			padding: 100px 0;

			.plus-pattern,
			.diamond-pattern {
				display: none;
			}

			.inner-wrap {
				.info {
					width: 100%;
					float: none;
					margin-top: 0;
				}

				.side {
					width: 100%;
					margin: 0;
					margin-top: 75px;
				}
			}
		}

		@include max-down(750px) {
			padding: 75px 0;

			.inner-wrap {
				width: calc(100% - 40px);

				.side {
					.internships {
						.info-list {
							grid-template-columns: 1fr;
						}
					}
				}
			}
		}
	}

	.cu-section__stats.no-change {
		margin-top: 100px;

		@include max-down(600px) {
			margin-top: 65px;
		}
	}

	.expanders-wrap {
		position: relative;
		z-index: 2;
		padding: 40px 0 155px 0;

		&.first {
			margin-top: 140px;
			background: $lgray;
		}

		.cu-part__expanders {
			width: 1170px;
			margin: 0 auto;
		}

		.programs {
			width: 1170px;
			margin: 0 auto;

			.program {
				margin-bottom: 30px;
				background: $blue;
				position: relative;
				padding: 30px 165px 25px 30px;
				cursor: pointer;

				&:last-child {
					margin-bottom: 0;
				}

				h3 {
					font-family: $sc_font;
					font-size: 22px;
					font-weight: 600;
					color: $white;
				}

				.button-wrap {
					width: 130px;
					height: 100%;
					position: absolute;
					top: 0;
					right: 0;

					.btn {
						display: flex;
						width: 100%;
						height: 100%;
						min-width: 0;
						justify-content: center;
						align-items: center;
					}
				}
			}
		}

		@include max-down(1300px) {
			.cu-part__expanders,
			.programs {
				width: 90%;
			}
		}

		@include max-down(1000px) {
			padding-bottom: 100px;

			&.first {
				margin-top: 100px;
			}
		}

		@include max-down(750px) {
			&.first {
				margin-top: 75px;
			}
		}

		@include max-down(600px) {
			padding-bottom: 75px;

			.cu-part__expanders {
				width: calc(100% - 40px);
			}

			.programs {
				width: calc(100% - 40px);

				.program h3 {
					font-size: 1rem;
				}
			}
		}
	}

	.cu-section__half.two-images {
		position: relative;
		z-index: 2;
		padding-bottom: 150px;
	}

	.blue-background-wrap {
		position: relative;
		z-index: 1;
		padding-bottom: 100px;

		.diamond-pattern {
			display: block;
			width: 470px;
			height: 827px;
			position: absolute;
			top: -180px;
			left: -140px;
			z-index: 2;
			background: url('/wp-content/themes/cornerstone-2024/svg/diamond-pattern-gold-5-column.svg') center no-repeat;
			background-size: contain;
		}

		&:after {
			content: '';
			display: block;
			width: 100%;
			height: calc(100% - 360px);
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
			background: $blue;
		}

		&.no-cs1:after {
			height: 100%;
		}

		&.no-cs1.with-testimonials:after {
			height: calc(100% - 360px);
		}
		
		&.no-testimonials.no-faculty-list {
			display: none !important;
			&:after {
				display: none !important;
			}
		}

		.cu-section__testimonial {
			position: relative;
			z-index: 2;
		}

		.cu-section__faculty-list {
			position: relative;
			z-index: 2;
			padding-bottom: 75px;
		}

		@include max-down(1000px) {
			padding-bottom: 5vw;

			.diamond-pattern {
				display: none;
			}
		}
	}

	.cu-section__programs-list {
		padding-bottom: 220px;

		@include max-down(1000px) {
			padding-bottom: 100px;
		}

		@include max-down(600px) {
			padding-bottom: 75px;
		}
	}

	.contact-bar-wrap {
		position: relative;
		padding-bottom: 245px;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: 45px;
			left: -205px;
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gray.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(1000px) {
			padding-bottom: 100px;

			.plus-pattern {
				display: none;
			}
		}

		@include max-down(600px) {
			padding-bottom: 75px;
		}
	}
}