.page-template-page-tuition-fees {
	.cost-wrap {
		position: relative;

		.triangle-pattern {
			display: block;
			width: 489px;
			height: 539px;
			position: absolute;
			top: 20px;
			left: -100px;
			background: url('/wp-content/themes/cornerstone-2024/svg/triangle-pattern-spread.svg') center no-repeat;
			background-size: contain;
		}

		.inner-wrap {
			width: 875px;
			position: relative;
			z-index: 2;
			margin: 0 auto;
			padding: 140px 0;

			.info {
				width: 800px;
				margin: 0 auto 65px auto;
			}

			.costs,
			.financial-aid,
			.total {
				padding: 40px 0;
				border-top: 2px solid $gold;

				h4 {
					margin-top: 10px;
					font-family: $c_font;
					font-size: 0.777rem;
					font-weight: 600;
					letter-spacing: 0.08em;
					color: $gray;
					text-transform: uppercase;
				}

				.item-cost {
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);


					font-family: $sc_font;
					font-size: 40px;
					font-weight: 800;
					color: $blue;

					span {
						font-size: 24px;
					}
				}

				.item {
					width: calc(100% - 160px);
					position: relative;
					margin: 0 auto 40px auto;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.financial-aid,
			.total {
				.item-cost {
					font-size: 54px;
				}
			}

			.note {
				width: 690px;
				margin: 0 auto;

				p {
					font-size: 0.875rem;
					line-height: 1.4em;
				}
			}
		}

		@include max-down(1160px) {
			.inner-wrap {
				.costs {
					.item-cost {
						font-size: 32px;
					}
				}

				.financial-aid,
				.total {
					.item-cost {
						font-size: 44px;
					}
				}
			}
		}

		@include max-down(1000px) {
			.triangle-pattern {
				display: none;
			}

			.inner-wrap {
				width: 90%;
				padding: 100px 0;

				.info {
					width: 95%;
				}

				.note {
					width: 95%;
				}
			}
		}

		@include max-down(750px) {
			.inner-wrap {
				.costs,
				.financial-aid,
				.total {
					.item-cost {
						font-size: 28px;

						span {
							font-size: 1rem;
						}
					}

					.item {
						width: calc(100% - 40px);
					}
				}

				.financial-aid,
				.total {
					.item-cost {
						font-size: 44px;
					}
				}

				.note {
					p {
						font-size: 0.777rem;
					}
				}
			}
		}

		@include max-down(600px) {
			.inner-wrap {
				width: calc(100% - 40px);

				.costs,
				.financial-aid,
				.total {
					text-align: center;

					.item-cost {
						display: block;
						position: static;
						transform: none;
						margin-top: 15px;
					}
				}
			}
		}
	}

	.background-wrap {
		position: relative;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: calc(100% - 345px);
			position: absolute;
			top: 0;
			left: 0;
			background: $blue;

			@include max-down(750px) {
				height: calc(100% - 250px);
			}
		}

		&.no-testimonial {
			&:before {
				height: 100%;
			}
		}

		.cu-section__stats {
			padding-top: 140px;
			padding-bottom: 155px;

			@include max-down(750px) {
				padding: 100px 0;
			}
		}
	}

	.expanders-wrap {
		.cu-section__simple-content {
			.inner-wrap {
				padding-top: 195px;
				padding-bottom: 60px;

				@include max-down(1000px) {
					padding-top: 150px;
				}

				@include max-down(750px) {
					padding-top: 100px;
				}
			}
		}

		.cu-part__expanders {
			width: 1170px;
			margin: 0 auto;

			@include max-down(1300px) {
				width: 90%;
			}

			@include max-down(600px) {
				width: calc(100% - 40px);
			}
		}
	}

	.cu-section__contact-bar {
		margin-top: 220px;

		@include max-down(750px) {
			margin-top: 100px;
		}
	}

	.page-background-wrap {
		padding-bottom: 280px;

		@include max-down(750px) {
			padding-bottom: 100px;
		}
	}
}