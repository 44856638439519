.page-template-page-rentable-venues {
	.page-background-wrap {
		padding-bottom: 340px;

		@include max-down(1200px) {
			padding-bottom: 250px;
		}

		@include max-down(1000px) {
			padding-bottom: 200px;
		}

		@include max-down(600px) {
			padding-bottom: 150px;
		}
	}

	.cu-section__image-slider {
		margin-top: -340px;

		@include max-down(1200px) {
			margin-top: -250px;
		}

		@include max-down(1000px) {
			margin-top: -200px;
		}

		@include max-down(600px) {
			margin-top: -150px;
		}
	}

	.contact-bar-wrap {
		position: relative;
		padding-top: 190px;
		padding-bottom: 245px;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -40px;
			left: -205px;
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(1000px) {
			padding-top: 100px;
			padding-bottom: 100px;

			.plus-pattern {
				display: none;
			}
		}

		@include max-down(600px) {
			padding-top: 75px;
			padding-bottom: 75px;
		}
	}
}