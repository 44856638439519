.cu-section__cta {
	position: relative;
	z-index: 2;
	font-size: 0;

	&.float-above {
		margin-top: -40px;
	}

	.left {
		display: inline-block;
		vertical-align: bottom;
		width: 65%;
		background: $dblue;
		padding: 55px 0;
		text-align: center;
		font-size: 0;

		h2 {
			display: inline-block;
			vertical-align: middle;
			margin-right: 45px;

			font-size: 56px;
			letter-spacing: 0.12em;
		}

		.button-wrap {
			display: inline-block;
			vertical-align: middle;
			padding-top: 10px;

			span {
				display: inline-block;
				vertical-align: middle;
				margin-right: 30px;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	.right {
		display: inline-block;
		vertical-align: bottom;
		width: 35%;
		background: $offgray;
		padding: 45px 0;
		text-align: center;
		font-size: 0;

		h2 {
			display: inline-block;
			vertical-align: middle;
			margin-right: 45px;

			font-size: 36px;
			letter-spacing: 0.14em;
		}

		nav {
			display: inline-block;
			vertical-align: middle;
			padding-top: 5px;
			font-size: 0;

			li {
				display: inline-block;
				vertical-align: middle;
				margin-right: 20px;

				&:last-child {
					margin-right: 0;
				}

				a {
					@include hidden-text;
					display: block;
					width: 100%;
					height: 100%;
					text-decoration: none;
				}

				&.instagram {
					width: 24px;
					height: 24px;
					background: url('/wp-content/themes/cornerstone-2024/svg/social-instagram-white.svg') center no-repeat;
					background-size: contain;

					@include non-touch {
						&:hover {
							background: url('/wp-content/themes/cornerstone-2024/svg/social-instagram-blue.svg') center no-repeat;
							background-size: contain;
						}
					}
				}

				&.facebook {
					width: 24px;
					height: 24px;
					background: url('/wp-content/themes/cornerstone-2024/svg/social-facebook-white.svg') center no-repeat;
					background-size: contain;

					@include non-touch {
						&:hover {
							background: url('/wp-content/themes/cornerstone-2024/svg/social-facebook-blue.svg') center no-repeat;
							background-size: contain;
						}
					}
				}

				&.twitter {
					width: 24px;
					height: 20px;
					background: url('/wp-content/themes/cornerstone-2024/svg/social-twitter-white.svg') center no-repeat;
					background-size: contain;

					@include non-touch {
						&:hover {
							background: url('/wp-content/themes/cornerstone-2024/svg/social-twitter-blue.svg') center no-repeat;
							background-size: contain;
						}
					}
				}

				&.youtube {
					width: 50px;
					height: 21px;
					background: url('/wp-content/themes/cornerstone-2024/svg/social-youtube-white.svg') center no-repeat;
					background-size: contain;

					@include non-touch {
						&:hover {
							background: url('/wp-content/themes/cornerstone-2024/svg/social-youtube-blue.svg') center no-repeat;
							background-size: contain;
						}
					}
				}

				&.linkedin {
					width: 24px;
					height: 24px;
					background: url('/wp-content/themes/cornerstone-2024/svg/social-linkedin-white.svg') center no-repeat;
					background-size: contain;

					@include non-touch {
						&:hover {
							background: url('/wp-content/themes/cornerstone-2024/svg/social-linkedin-blue.svg') center no-repeat;
							background-size: contain;
						}
					}
				}

				&.zeemee {
					width: 24px;
					height: 24px;
					background: url('/wp-content/themes/cornerstone-2024/svg/social-zeemee-white.png') center no-repeat;
					background-size: contain;

					@include non-touch {
						&:hover {
							background: url('/wp-content/themes/cornerstone-2024/svg/social-zeemee-blue.png') center no-repeat;
							background-size: contain;
						}
					}
				}
			}
		}
	}

	@include max-down(1700px) {
		&.float-above {
			margin-top: -110px;
		}

		.left {
			padding-left: 5vw;
			padding-right: 5vw;

			h2 {
				width: calc(100% - 160px);
				margin-right: 0;
				padding-right: 20px;
				text-align: left;
			}

			.button-wrap {
				span {
					display: block;
					margin-right: 0;
					margin-bottom: 15px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	@include max-down(1550px) {
		&.float-above {
			margin-top: -65px;
		}

		.right {
			h2 {
				display: block;
				margin-right: 0;
			}

			nav {
				display: block;
				margin-top: 15px;
			}
		}
	}

	@include max-down(1160px) {
		&.float-above {
			margin-top: -125px;
		}

		.left {
			padding-left: 5%;
			padding-right: 5%;
		}

		.right {
			padding-left: 5%;
			padding-right: 5%;
		}
	}

	@include max-down(1000px) {
		.left {
			h2 {
				display: block;
				width: 100%;
				padding-right: 0;
				text-align: center;
			}

			.button-wrap {
				margin-top: 15px;

				span {
					display: inline-block;
					margin-right: 15px;
					margin-bottom: 0;
				}
			}
		}

		.right {
			nav li {
				margin-right: 10px;
			}
		}
	}

	@include max-down(750px) {
		&.float-above {
			margin-top: 0;
		}

		.left {
			width: 100%;
			float: none;
		}

		.right {
			width: 100%;
			float: none;

			h2 {
				display: inline-block;
				margin-right: 25px;
			}

			nav {
				display: inline-block;
				margin-top: 0;
			}
		}
	}

	@include max-down(600px) {
		.right {
			h2 {
				font-size: 26px;
				line-height: 1em;
				letter-spacing: 0.1em;
			}
		}
	}

	@include max-down(420px) {
		.left {
			.button-wrap {
				span {
					display: block;
					margin-right: 0;
					margin-bottom: 15px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		.right {
			h2 {
				display: block;
				margin-right: 0;
			}

			nav {
				display: block;
				margin-top: 15px;
			}
		}
	}
}