.post-template-post-template-post-text {
	.cu-section__simple-content:not(.main-content) {
		.inner-wrap {
			padding-bottom: 215px;
		}

		@include max-down(1350px) {
			.inner-wrap {
				padding-bottom: 100px;
			}
		}
	}

	.cu-section__simple-content.main-content {
		margin-top: -105px;

		.inner-wrap {
			padding-top: 0;
		}

		@include max-down(1350px) {
			margin-top: 0;
		}
	}

	.cu-section__post-list {
		position: relative;
		padding-top: 0;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -135px;
			left: -185px;
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;
		}

		> .info {
			position: relative;
			z-index: 2;
		}

		.post-list {
			position: relative;
			z-index: 2;
		}

		@include max-down(1000px) {
			.plus-pattern {
				display: none;
			}
		}
	}
}