.page-template-page-seminary-next-steps-ind {
	.cu-section__number-list {
		position: relative;

		.triangle-pattern {
			display: block;
			width: 489px;
			height: 539px;
			position: absolute;
			top: 20px;
			left: -100px;
			background: url('/wp-content/themes/cornerstone-2024/svg/triangle-pattern-spread.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(1000px) {
			.triangle-pattern {
				display: none;
			}
		}
	}
	.things-to-know-wrap {
		padding-bottom: 150px;

		.cu-section__simple-content {
			padding-top: 0;

			.inner-wrap {
				padding-top: 0;
			}
		}
		
		.cu-part__expanders {
			width: 1170px;
			position: relative;
			z-index: 2;
			margin: 0 auto;
		}

		@include max-down(1300px) {
			.cu-part__expanders {
				width: 90%;
			}
		}

		@include max-down(600px) {
			.cu-part__expanders {
				width: calc(100% - 40px);
			}
		}
	}

	.cu-section__testimonial {
		position: relative;
		z-index: 3;
	}

	.important-dates-wrap {
		position: relative;
		padding-bottom: 100px;

		.diamond-pattern {
			display: block;
			width: 469px;
			height: 657px;
			position: absolute;
			top: -520px;
			right: 0;
			z-index: 2;
			background: url('/wp-content/themes/cornerstone-2024/svg/diamond-pattern-gold-5-column.svg') center no-repeat;
			background-size: contain;
		}

		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 1300px;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
			background: $lgray;
		}

		.cu-part__expanders {
			width: 1170px;
			position: relative;
			z-index: 3;
			margin: 0 auto;
		}

		@include max-down(1300px) {
			.diamond-pattern {
				right: -160px;
			}

			.cu-part__expanders {
				width: 90%;
			}
		}

		@include max-down(750px) {
			.diamond-pattern {
				display: none;
			}
		}

		@include max-down(600px) {
			.cu-part__expanders {
				width: calc(100% - 40px);
			}
		}
	}

	.meet-your-counselor-wrap {
		position: relative;
		padding: 140px 0;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -245px;
			right: -80px;
			transform: rotate(-90deg);
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;
		}

		.inner-wrap {
			width: 1250px;
			position: relative;
			margin: 0 auto;

			.left {
				@include section-line-before(60px, 20px);
				width: 750px;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				padding-left: 80px;
			}

			.right {
				width: 345px;
				margin: 0 0 0 auto;
			}
		}

		@include max-down(1600px) {
			.plus-pattern {
				right: -150px;
			}
		}

		@include max-down(1400px) {
			.plus-pattern {
				right: -300px;
			}

			.inner-wrap {
				width: 90%;

				.left {
					width: calc(100% - 400px);
				}
			}
		}

		@include max-down(1000px) {
			.plus-pattern {
				display: none;
			}
			
			.inner-wrap .left {
				@include section-line-before-break;
				padding-left: 0;
			}
		}

		@include max-down(750px) {
			padding: 100px 0;

			.inner-wrap {
				text-align: center;

				.left {
					width: 100%;
					position: static;
					transform: none;

					&:before {
						margin-left: auto;
						margin-right: auto;
					}
				}

				.right {
					margin: 45px auto 0 auto;
					text-align: left;
				}
			}
		}

		@include max-down(600px) {
			padding: 75px 0;

			.inner-wrap {
				width: calc(100% - 40px);
			}
		}

		@include max-down(480px) {
			.inner-wrap .right {
				width: 100%;
			}
		}
	}
}