.page-template-page-landing-page {
	@include max-down(1000px) {
		padding-top: 58px;
	}

	@include max-down(600px) {
		padding-top: 54px;
	}

	.hero-wrap {
		position: relative;
		z-index: 2;

		.inner-wrap {
			height: 900px;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 3;
				opacity: 0.45;
				background: $gold;
				background: linear-gradient(45deg, $gold 5%, $blue 85%); 
			}

			.tablet-image,
			.mobile-image {
				display: none;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
			}

			.mobile-image {
				z-index: 2;
			}
			
			.vertical-center {
				position: relative;
				z-index: 4;
			}
		}

		.inner-content-wrap {
			width: 1170px;
			margin: 0 auto;

			h1 {
				width: 525px;
				position: relative;

				&:before {
					width: 60px;
					position: absolute;
					left: -80px;
					top: 23px;
				}
			}
		}

		.form-wrap {
			width: 575px;
			position: absolute;
			top: 50%;
			right: 80px;
			transform: translateY(-50%);
			z-index: 5;
			background: rgba($altgray, 0.85);

			.fsBody .fsForm {
				margin: 0;
				padding: 0;
			}

			iframe {
				display: block;
				width: 100%;
			}

			.gform_wrapper {
				width: 90%;
				margin: 0 auto;
				padding: 40px 0;

				.gform_body {
					padding-bottom: 0;

					.gfield:last-child {
						padding-bottom: 0;
					}
				}

				.gfield_label {
					display: block;
					padding: 0;
					margin-bottom: 10px;

					font-family: $c_font;
					font-size: 1rem;
					font-weight: 600;
					letter-spacing: 0.08em;
					text-transform: uppercase;
					color: $blue;
				}

				input[type="text"],
				input[type="number"],
				input[type="email"],
				input[type="tel"],
				select,
				textarea {
					appearance: none;
					width: 100%;
					height: 44px;
					border: 2px solid $blue;
					border-radius: 0;
					outline: 0;
					background: $white;
					padding: 0 15px;
					margin: 0;

					font-family: $n_font;
					font-size: 1rem;
					text-transform: none;
					color: $black;
				}

				select {
					padding-right: 30px;
					background: $white url('../svg/select-arrow-blue.svg') right 15px center no-repeat;
					background-size: 12px 6px;
				}

				textarea {
					height: 240px;
				}

				.gform_footer {
					padding: 0;
					margin-top: 50px;
					text-align: center;

					.gform_button {
						min-width: 100%;
						padding: 20px 25px;
						background: $gold;

						@include non-touch {
							&:hover {
								background: $blue;
								color: $gold;
								border-color: $blue;
							}
						}
					}
				}
			}
		}

		@include max-down(1400px) {
			.inner-content-wrap {
				width: 85%;
			}

			.form-wrap {
				width: 40%;
				right: 40px;
			}
		}

		@include max-down(1000px) {
			.inner-wrap {
				height: 700px;
			}

			.inner-content-wrap {
				width: 70%;

				h1 {
					width: 100%;
				}
			}

			.form-wrap {
				width: 100%;
				position: static;
				transform: none;
			}
		}

		@include max-down(600px) {
			.inner-wrap {
				height: 550px;
			}
		}
	}

	section.cu-section__simple-content {
		position: relative;
		z-index: 1;

		&:before {
			content: '';
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			top: 0px;
			right: -110px;
			transform: rotate(-90deg);
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;
		}

		.inner-wrap {
			position: relative;
			z-index: 2;
		}

		&.no-pattern {
			padding-top: 0;

			&:before {
				display: none;
			}

			.inner-wrap {
				padding: 0 0 150px;

				@include max-down(600px) {
					padding-bottom: 100px;
				}
			}
		}
	}

	.cu-section__stats {
		padding-bottom: 400px;
	}

	.expanders-wrap {
		padding-bottom: 180px;

		.cu-section__simple-content {
			.inner-wrap {
				padding-top: 110px;
			}
		}
		.cu-part__expanders {
			width: 1170px;
			margin: 0 auto;

			@include max-down(1300px) {
				width: 90%;
			}

			@include max-down(600px) {
				width: calc(100% - 40px);
			}
		}
	}
}


body .page-template-page-landing-page .hero-wrap picture {
	height: 100%;
}
body .landing-page-header-wrap .header .logo {
	background: url(/wp-content/themes/cornerstone/public/svg/full-logo-white.svg) 50% no-repeat;
	background-size: contain;
}
@media only screen and (max-width: 1299px) {
	body .landing-page-header-wrap .header .logo {
		width: 200px;
		height: 55px;
		background: url(/wp-content/themes/cornerstone/public/svg/full-logo-white-stacked.svg) 50% no-repeat;
		background-size: contain;
	}
}
@media only screen and (max-width: 449px) {
	body .landing-page-header-wrap .header .logo {
		width: 55px;
		height: 57px;
		background-image: url(/wp-content/themes/cornerstone/public/svg/logo-mark-white.svg);
	}
}
body .page-template-page-landing-page .hero-wrap .inner-wrap .vertical-center {
	position: absolute;
	top: 0;
}
@media only screen and (max-width:1159px) {
	.page-template-page-landing-page .hero-wrap .inner-wrap .image {
	display: none;
	}
	.page-template-page-landing-page .hero-wrap .inner-wrap .tablet-image {
	display: block;
	}
}
@media only screen and (max-width:600px) {
	.page-template-page-landing-page .hero-wrap .inner-wrap .tablet-image {
	display: none;
	}
	.page-template-page-landing-page .hero-wrap .inner-wrap .mobile-image {
	display: block;
	}
	.mobile-menu.landing-page .inner-wrap {
		padding-top: 160px !important;
	}
}