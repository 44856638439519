.cu-section__simple-content {
	position: relative;
	z-index: 2;
	background: rgba($offgray, 0.15);
	padding-top: 40px;

	&.tbx-animated {
		.inner-wrap {
			@include vertical-line-before;

			h1,
			h2,
			.copy,
			.button-wrap,
			.jump-nav,
			.right,
			.post-details {
				opacity: 0;
			}

			&:before {
				height: 0;
				transition: height .7s;
			}
		}

		&.tbx-animated-siv-ft {
			.inner-wrap {
				&:before {
					height: 60px;
				}

				h1,
				h2 {
					@include fadeInUp($duration: 0.7s, $delay: 0.7s);
				}

				.copy {
					@include fadeInUp($duration: 0.7s, $delay: 0.95s);
				}

				.button-wrap,
				.jump-nav,
				.right,
				.post-details {
					@include fadeInUp($duration: 0.7s, $delay: 1.3s);
				}
			}
		}

		@include max-down(600px) {
			.inner-wrap {
				h1,
				h2,
				.copy,
				.button-wrap,
				.jump-nav,
				.right,
				.post-details {
					opacity: 1;
					animation: none !important;
				}

				&:before {
					height: 60px;
				}
			}
		}
	}

	.back-wrap {
		width: calc(100% - 150px);
		margin: 0 auto;
	}

	.inner-wrap {
		width: 800px;
		margin: 0 auto;
		padding: 65px 0 90px 0;
		text-align: center;

		.copy {
			text-align: left;
		}

		.button-wrap {
			margin-top: 35px;
			font-size: 0;

			span {
				display: inline-block;
				vertical-align: middle;
				margin-right: 20px;

				&:last-child {
					margin-right: 0;
				}
			}

			&.four-buttons {
				width: 380px;
				margin-left: auto;
				margin-right: auto;

				span {
					margin-bottom: 20px;

					&:nth-child(2n) {
						margin-right: 0;
					}

					&:last-child {
						margin-right: 0;
					}
				}

				@include max-down(450px) {
					width: 100%;

					span {
						display: block;
						margin-right: 0;
					}
				}
			}
		}
	}

	.jump-nav {
		display: inline-block;
		position: relative;
		margin-top: 40px;
		text-align: center;

		.title {
			display: inline-block;
			vertical-align: middle;
			margin-right: 20px;

			font-family: $c_font;
			font-size: 1rem;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 0.08em;
			color: $blue;
		}

		.toggle {
			display: inline-block;
			vertical-align: middle;
			width: 220px;
			position: relative;
			padding: 10px 30px 12px 16px;
			border: 2px solid $gold;
			overflow: hidden;
			white-space: nowrap;
			background: $lgray;

			font-family: $c_font;
			font-size: 1rem;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 0.08em;
			color: $blue;
			text-align: left;
			text-overflow: ellipsis;

			&:after {
				content: '';
				display: block;
				width: 12px;
				height: 10px;
				position: absolute;
				top: 50%;
				right: 10px;
				transform: translateY(-50%);
				background: url('/wp-content/themes/cornerstone-2024/svg/jump-nav-arrow-blue.svg') center no-repeat;
				background-size: contain;
			}
		}

		.nav {
			display: none;
			width: 220px;
			position: absolute;
			top: calc(100% - 2px);
			right: 0;
			border-right: 2px solid $gold;
			border-bottom: 2px solid $gold;
			border-left: 2px solid $gold;
			background: $lgray;

			text-align: left;

			li {
				display: block;
				padding: 10px 16px 12px 16px;
				cursor: pointer;

				font-family: $c_font;
				font-size: 1rem;
				font-weight: 600;
				text-transform: uppercase;
				letter-spacing: 0.08em;
				color: $blue;

				@include non-touch {
					transition: background .3s;

					&:hover {
						background: $gold;
					}
				}
			}
		}

		.mobile-toggle {
			appearance: none;
			display: none;
			vertical-align: middle;
			width: 220px;
			position: relative;
			padding: 10px 30px 12px 16px;
			border: 2px solid $gold;
			overflow: hidden;
			white-space: nowrap;
			background: $lgray url('/wp-content/themes/cornerstone-2024/svg/jump-nav-arrow-blue.svg') right 10px center no-repeat;
			background-size: 12px 10px;

			font-family: $c_font;
			font-size: 1rem;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 0.08em;
			color: $blue;
			text-align: left;
			text-overflow: ellipsis;
		}
	}

	#search-form {
		display: inline-block;
		width: 750px;
		position: relative;
		font-size: 0;

		&:before {
			content: '';
			display: block;
			width: 23px;
			height: 24px;
			position: absolute;
			top: 10px;
			left: 20px;
			background: url('/wp-content/themes/cornerstone-2024/svg/search-icon-gold.svg') center no-repeat;
			background-size: contain;
		}

		input[type="text"] {
			appearance: none;
			display: block;
			width: 585px;
			height: 45px;
			padding: 0 10px 5px 70px;
			margin: 0;
			margin-right: 15px; 
			border: 0;
			border-radius: 0;
			border-bottom: 1px solid $blue;
			background: none;

			font-family: $ec_font;
			font-size: 32px;
			font-weight: 600;
			letter-spacing: 0.18em;
			color: $blue;
		}

		button[type="submit"] {
			width: 140px;
			position: absolute;
			top: 2px;
			right: 0;
		}

		.buckets {
			margin-top: 30px;

			h2,
			label {
				display: inline-block;
				vertical-align: middle;
				margin: 0;

				font-size: 1rem;
				font-family: $c_font;
				text-transform: uppercase;
				color: $blue;
			}

			h2 {
				margin-right: 30px;
			}

			.inputs,
			.input {
				display: inline-block;
				vertical-align: middle;
			}

			.input {
				margin-right: 30px;

				&:last-child {
					margin-right: 0;
				}
			}

			input[type="radio"] {
				display: none;
			}

			.input label:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				width: 14px;
				height: 14px;
				margin-top: -3px;
				margin-right: 10px;
				border: 2px solid $gold;
				border-radius: 100%;
			}

			input[type="radio"]:checked + label:before {
				background: $gold;
			}
		}
	}

	.search-categories {
		margin-top: 100px;

		h2,
		.categories {
			display: inline-block;
			vertical-align: midde;
		}

		h2,
		.mobile-headline {
			margin-right: 25px;
			margin-bottom: 0;

			font-size: 1rem;
			font-family: $c_font;
			text-transform: uppercase;
			color: $blue;
		}

		.mobile-headline {
			display: none;
			margin-right: 0;
			margin-bottom: 15px;
		}

		button[type="button"] {
			padding: 15px 20px;

			font-size: 1rem;
			font-family: $c_font;
			text-transform: uppercase;
			color: $blue;

			&.active {
				position: relative;
				background: $white;
				box-shadow: 0px 1px 6px rgba($black, 0.16);

				&:after {
					content: '';
					display: block;
					width: 100%;
					height: 6px;
					position: absolute;
					top: 100%;
					left: 0;
					background: $white;
				}
			}
		}
	}

	.post-details {
		font-size: 0;

		.detail {
			display: inline-block;
			vertical-align: middle;
			position: relative;
			padding: 0 25px;

			font-family: $ec_font;
			font-size: 1.5rem;
			font-weight: 600;
			letter-spacing: 0.07em;
			text-transform: uppercase;
			color: $blue;

			&:after {
				content: '';
				display: block;
				width: 2px;
				height: 30px;
				position: absolute;
				bottom: -4px;
				right: 0;
				background: $gold;
			}

			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}

	&.no-bg {
		background: none;
		padding-top: 0;

		.jump-nav {
			.toggle {
				background: $white;
			}

			.nav {
				background: $white;
			}

			.mobile-toggle {
				background-color: $white;
			}
		}
	}

	&.no-bottom-padding {
		.inner-wrap {
			padding-bottom: 0;
		}
	}

	&.blue-bg {
		background: $blue;
	}

	&.has-side-jump-nav {
		.inner-wrap {
			width: 850px;

			.side-jump-nav-wrap {
				@include clearfix;
			}

			.copy {
				width: 560px;
				float: left;
			}

			.right {
				width: 260px;
				float: right;
				padding-left: 35px;
				padding-top: 5px;
				padding-bottom: 5px;
				border-left: 2px solid $gold;
				text-align: left;

				h3 {
					margin-bottom: 5px;
					font-family: $c_font;
					font-size: 1rem;
					font-weight: 600;
					letter-spacing: 0.08em;
					text-transform: uppercase;
					color: $gray;
				}

				ul li {
					margin-bottom: 5px;

					&:last-child {
						margin-bottom: 0;
					}

					a {
						font-family: $c_font;
						font-size: 1rem;
						font-weight: 600;
						letter-spacing: 0.08em;
						text-transform: uppercase;
						text-decoration: none;
						color: $blue;

						@include non-touch {
							transition: color .3s;

							&:hover {
								color: $gold;
							}
						}
					}
				}
			}
		}

		@include max-down(1000px) {
			.inner-wrap {
				width: 90%;

				.copy {
					width: calc(100% - 300px);
				}
			}
		}

		@include max-down(750px) {
			.inner-wrap {
				.copy {
					width: 100%;
					float: none;
				}

				.right {
					display: none;
				}
			}
		}
	}

	@include max-down(1000px) {
		.back-wrap {
			width: 90%;
		}

		.inner-wrap {
			width: 90%;

			#search-form {
				width: 100%;
				text-align: left;

				input[type="text"] {
					width: calc(100% - 165px);
				}

				.buckets {
					h2 {
						display: block;
						margin-right: 0;
						margin-bottom: 15px;
					}

					.inputs {
						display: block;
					}

					.input {
						display: block;
						margin-right: 0;
						margin-bottom: 10px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}

			.search-categories {
				width: 100%;
				margin-top: 45px;
				margin-left: auto;
				margin-right: auto;
				text-align: left;

				.mobile-headline {
					display: block;
				}

				.desktop-headline {
					display: none;
				}

				.scroll {
					white-space: nowrap;
					overflow-x: auto;
					overflow-y: hidden;
				}
			}
		}
	}

	@include max-down(600px) {
		.inner-wrap {
			.button {
				span {
					display: block;
					margin-bottom: 20px;
					margin-right: 0;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.jump-nav {
				.toggle {
					display: none;
				}

				.mobile-toggle {
					display: inline-block;
				}
			}

			#search-form {
				&:before {
					left: 5px;
				}

				input[type="text"] {
					font-size: 20px;
					padding-left: 40px;
				}
			}
		}
	}

	@include max-down(480px) {
		.inner-wrap {
			padding-bottom: 75px;
		}
	}
}