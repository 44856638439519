.page-template-page-resources {
	.cu-section__simple-content {
		z-index: 3;
	}

	.resources-wrap {
		position: relative;
		padding: 120px 0 235px 0;

		.plus-pattern-top {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			top: 340px;
			left: -140px;
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;
		}

		.diamond-pattern {
			display: block;
			width: 511px;
			height: 585px;
			position: absolute;
			top: 40%;
			right: 15px;
			background: url('/wp-content/themes/cornerstone-2024/svg/diamond-pattern-gold-wide.svg') center no-repeat;
			background-size: contain;
		}

		.plus-pattern-bottom {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -195px;
			left: -140px;
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;
		}

		.resource-category {
			width: 1175px;
			position: relative;
			z-index: 2;
			margin: 0 auto 205px auto;

			&:last-child {
				margin-bottom: 0;
			}

			h2 {
				font-family: $sc_font;
				font-size: 28px;
				font-weight: 800;
				text-transform: none;
				letter-spacing: 0;
			}

			.cu-part__expanders {
				margin-top: 70px;
			}
		}

		@include max-down(1300px) {
			.plus-pattern-top,
			.plus-pattern-bottom {
				left: -350px;
			}

			.diamond-pattern {
				right: -300px;
			}

			.resource-category {
				width: 90%;
			}
		}

		@include max-down(1160px) {
			.resource-category {
				margin-bottom: 120px;
			}
		}

		@include max-down(1050px) {
			.plus-pattern-top,
			.plus-pattern-bottom {
				display: none;
			}
		}

		@include max-down(600px) {
			.diamond-pattern {
				display: none;
			}
		}
	}
}