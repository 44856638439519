.page-template-page-hub-academics,
.page-template-page-hub-academics-degree-type {
	.page-background-wrap {
		padding-bottom: 175px;

		@include max-down(750px) {
			padding-bottom: 100px;
		}
	}
}

.page-template-page-hub-academics-degree-type {
	.testimonial-wrap {
		position: relative;

		.diamond-pattern {
			display: block;
			width: 469px;
			height: 445px;
			position: absolute;
			bottom: 115px;
			left: 10px;
			z-index: 1;
			background: url('/wp-content/themes/cornerstone-2024/svg/diamond-pattern-gold-5-column.svg') top center no-repeat;
			background-size: 469px 585px;
		}

		.cu-section__testimonial {
			z-index: 2;
		}

		@include max-down(1000px) {
			.diamond-pattern {
				display: none;
			}
		}
	}

	.cu-section__contact-bar {
		margin-top: 150px;

		@include max-down(1000px) {
			margin-top: 100px;
		}

		@include max-down(600px) {
			margin-top: 65px;
		}
	}
}