.cu-section__testimonial {
	width: 1600px;
	height: 720px;
	position: relative;
	margin: 0 auto;

	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		opacity: 0.45;
		background: $gold;
		background: linear-gradient(45deg, $gold 5%, $blue 85%); 
	}

	.inner-wrap {
		width: calc(100% - 340px);
		position: relative;
		z-index: 2;
		margin: 0 auto;

		blockquote {
			width: 575px;
			position: relative;
			margin: 0 0 0 auto;

			&:before {
				content: '';
				display: block;
				width: 71px;
				height: 57px;
				position: absolute;
				top: -20px;
				left: -50px;
				background: url('/wp-content/themes/cornerstone-2024/svg/quote-gold.svg') center no-repeat;
				background-size: contain;
			}

			.copy {
				position: relative;
				z-index: 2;

				p {
					font-family: $s_font;
					font-size: 48px;
					line-height: 1em;
					color: $white;
				}
			}

			cite {
				font-family: $sc_font;
				font-size: 20px;
				font-weight: 900;
				color: $white;

				&:before {
					content: '';
					display: block;
					width: 30px;
					height: 2px;
					background: $gold;
					margin: 25px 0;
				}
			}
		}

		.button-wrap {
			width: 575px;
			margin: 20px 0 0 auto;
		}
	}

	&.text-left {
		.inner-wrap {
			blockquote,
			.button-wrap {
				margin: 20px 0 0 0;
			}
		}
	}

	@include max-down(1750px) {
		width: 95%;
	}

	@include max-down(1400px) {
		.inner-wrap {
			width: 80%;
			margin: 0 5% 0 auto;
		}
	}

	@include max-down(1000px) {
		.inner-wrap blockquote {
			width: 100%;
		}
	}

	@include max-down(750px) {
		height: auto;

		.vertical-center,
		.vertical-center .cell {
			display: block;
		}

		.inner-wrap {
			padding: 100px 0;

			blockquote {
				.copy p {
					font-size: 34px;
				}
			}
		}
	}
}