.vertical-center {
	display: table;
	width: 100%;
	height: 100%;
	.cell {
		display: table-cell;
		vertical-align: middle;
	}

	&.bottom {
		.cell {
			vertical-align: bottom;
		}
	}
}

.object-fit {
	display: block;
	width: 100%;
	height: 100% !important;

	&.cover {
		object-fit: cover;
	}
	
	&.contain {
		object-fit: contain;
	}
}

.tbx-module__video-overlay-toggle {
	font-size: 0;

	.icon {
		display: inline-block;
		vertical-align: middle;
		width: 110px;
		height: 110px;
		position: relative;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			border-radius: 50%;
			background: $white;
			opacity: 0.3;
			animation: pulse 1s alternate infinite;
		}

		&:after {
			content: '';
			width: 60px;
			height: 60px;
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 2;
			transform: translate(-50%, -50%);
			background: url('/wp-content/themes/cornerstone-2024/svg/play-icon.svg') center no-repeat;
			background-size: contain;
		}
	}

	.text {
		display: inline-block;
		vertical-align: middle;
		margin-left: 20px;

		font-family: $c_font;
		font-size: 1rem;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: 0.08em;
		color: $white;

		&:first-child {
			margin-right: 20px;
			margin-left: 0;
		}
	}

	&.small {
		.icon {
			width: 80px;
			height: 80px;

			&:after {
				width: 44px;
				height: 44px;
			}
		}
	}

	&.gold {
		.icon {
			width: 60px;
			height: 60px;
			background: url('/wp-content/themes/cornerstone-2024/svg/play-icon-gold.svg') center no-repeat;
			background-size: contain;

			&:before,
			&:after {
				display: none;
			}
		}

		.text {
			color: $blue;
		}
	}

	@include max-down(600px) {
		.icon {
			width: 80px;
			height: 80px;

			&:after {
				width: 44px;
				height: 44px;
			}
		}

		&.gold {
			.icon {
				width: 44px;
				height: 44px;
			}
		}
	}
}

.default-page {
	* {
		margin: 20px 0;
		max-width: 100%;
	}
	h1, h2, h3, h4, h5, h6 {
		color: #000;
		line-height: 1.2em;
		font-weight: bold;
		margin: 32px 0 .5em 0;
	}
	h1 {
		font-size: 50px;
		@include max-down(1000px) {
			font-size: 44px;
		}
		@include max-down(769px) {
			font-size: 34px;
		}
	}
	h2 {
		font-size: 42px;
		@include max-down(1000px) {
			font-size: 38px;
		}
		@include max-down(769px) {
			font-size: 30px;
		}
	}
	h3 {
		font-size: 36px;
		@include max-down(1000px) {
			font-size: 32px;
		}
		@include max-down(769px) {
			font-size: 26px;
		}
	}
	h4 {
		font-size: 28px;
		@include max-down(1000px) {
			font-size: 26px;
		}
		@include max-down(769px) {
			font-size: 22px;
		}
	}
	h5 {
		font-size: 22px;
		@include max-down(769px) {
			font-size: 18px;
		}
	}
	h6 {
		font-size: 16px;
		@include max-down(769px) {
			font-size: 14px;
		}
	}
	p {
		font-family: $n_font;
		font-size: 18px;
		font-weight: normal;
		line-height: 1.5em;
		em {
			font-style: italic;
		}
		strong {
			font-weight: bold;
		}
		small {
			font-size: 14px;
			line-height: 1.2em;
		}
		a {
			color: inherit;
		}
		@include max-down(769px) {
			font-size: 16px;
		}
	}
	header > h1 {
		text-align: center;
	}
	article {
		max-width: 800px;
		margin: 100px auto;
		@include max-down(900px) {
			max-width: 90%;
		}
	}
	.wp-caption {
		max-width: calc(100% + 200px);
		margin: 40px -100px !important;
		.wp-caption-text {
			font-size: 14px;
			font-style: italic;
			text-align: left;
			margin-top: 0.5em;
		}
		@include max-down(1050px) {
			max-width: 100%;
			margin: 40px 0 !important;
		}
	}
	blockquote {
		position: relative;
		padding: 10px 0 10px 25px;
		&:before {
			content: '';
			display: block;
			width: 5px;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: #000;
		}
		p {
			margin-top: 0;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}