.page-template-page-landing-page-giving {
	@include max-down(1000px) {
		padding-top: 58px;
	}

	@include max-down(600px) {
		padding-top: 54px;
	}

	.cu-section__hero {
		padding-top: 121px;
		z-index: 2;
		
		.inner-wrap {
			margin: 0 auto;
		}
	}

	.main-content {
		position: relative;
		z-index: 1;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			top: -105px;
			right: -105px;
			transform: rotate(-90deg);
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(1000px) {
			.plus-pattern {
				display: none;
			}
		}
	}

	.cu-section__stats {
		padding: 0;
	}

	.image-video-wrap {
		width: 1470px;
		height: 710px;
		position: relative;
		z-index: 2;
		margin: 100px auto 0;

		.image {
			width: 100%;
			height: 100%;
			position: relative;
			z-index: 1;
		}

		button {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}

		@include max-down(1600px) {
			width: 95%;
		}

		@include max-down(1300px) {
			height: 650px;
		}

		@include max-down(1000px) {
			height: 600px;
		}

		@include max-down(600px) {
			width: calc(100% - 40px);
			height: 550px;
		}
	}

	.cu-section__featured-news {
		padding-top: 283px;
		margin-top: -175px;

		.cu-section__simple-content {
			.inner-wrap {
				padding-top: 0;
			}
		}
	}

	.signup-form-wrap {
		@include clearfix;
		width: 1470px;
		margin: 0 auto;
		padding: 45px 40px;
		background: $dblue;

		h2 {
			float: left;
		}

		.form {
			float: right;

			.gform_body {
				display: inline-block;
				vertical-align: middle;
				padding-bottom: 0;
				margin-right: 15px;

				.gfield {
					padding-bottom: 0;
				}

				label {
					display: none;
				}

				input[type="email"] {
					appearance: none;
					width: 400px;
					height: 42px;
					border: 0;
					border-radius: 0;
					outline: 0;
					border-bottom: 2px solid $white;
					background: none;

					font-family: $sc_font;
					font-size: 18px;
					font-weight: 700;
					color: $white;
				}
			}

			.gform_footer {
				display: inline-block;
				vertical-align: middle;
				padding-bottom: 0;

				button {
					min-width: 0;
					color: $white;
				}
			}
		}

		@include max-down(1600px) {
			width: 95%;
		}

		@include max-down(1450px) {
			position: relative;

			h2 {
				width: 47.5%;
				float: none;
			}

			.form {
				width: 45%;
				position: absolute;
				top: 50%;
				right: 40px;
				transform: translateY(-50%);

				.gform_body {
					width: calc(100% - 120px);

					input[type="email"] {
						width: 100%;
					}
				}
			}
		}

		@include max-down(750px) {
			h2 {
				width: 100%;
				text-align: center;
			}

			.form {
				width: 100%;
				position: static;
				transform: none;
				margin-top: 45px;
			}
		}

		@include max-down(600px) {
			width: calc(100% - 40px);
		}
	}

	.testimonial-wrap {
		position: relative;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 50%;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
			background: $blue;
		}

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			top: 45%;
			right: -35px;
			transform: rotate(-90deg);
			z-index: 2;
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;

			@include max-down(1000px) {
				display: none;
			}
		}

		.cu-section__testimonial {
			z-index: 3;
		}
	}

	.progress-wrap {
		background: $blue;

		.cu-section__simple-content {
			.inner-wrap {
				padding-top: 120px;
				padding-bottom: 0;
			}
		}

		.progress {
			width: 1470px;
			margin: 0 auto;
			padding: 100px 0 170px;

			.labels {
				font-size: 0;

				.label {
					display: inline-block;
					vertical-align: middle;
					width: 50%;

					h3 {
						display: inline-block;
						vertical-align: middle;
						margin-right: 80px;
					}

					span {
						display: inline-block;
						vertical-align: middle;

						font-family: $sc_font;
						font-size: 54px;
						font-weight: 900;
						color: $white;
					}

					&.current span {
						color: $gold;
					}
				}
			}

			.progress-bar {
				height: 80px;
				position: relative;
				border-left: 4px solid $white;
				border-right: 4px solid $white;
				margin-top: 40px;

				&:before {
					content: '';
					display: block;
					width: 100%;
					height: 4px;
					position: absolute;
					top: calc(50% - 2px);
					left: 0;
					background: $white;
				}

				.bar {
					display: block;
					width: 0;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
					background: $gold;
					transition: width .7s ease-out;
				}
			}

			&.tbx-animated {
				&.tbx-animated-siv-ft {
					.progress-bar .bar {
						width: var(--bar-width);
					}
				}
			}

			@include max-down(1600px) {
				width: 90%;
			}

			@include max-down(1300px) {
				.labels {
					.label {
						text-align: center;

						h3 {
							margin-right: 25px;
						}

						span {
							font-size: 42px;
						}
					}
				}
			}

			@include max-down(1000px) {
				padding-bottom: 100px;

				.labels {
					.label {
						display: block;
						width: 100%;
						margin-bottom: 25px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}

			@include max-down(600px) {
				padding: 60px 0;

				.labels {
					.label {
						span {
							font-size: 32px;
						}
					}
				}

				.progress-bar {
					height: 40px;
				}
			}
		}
	}

	.fw-image-wrap {
		position: relative;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 220px;
			position: absolute;
			top: 0;
			left: 0;
			background: $blue;
		}

		.inner-wrap {
			width: 1470px;
			height: 680px;
			position: relative;
			z-index: 2;
			margin: 0 auto;

			@include max-down(1600px) {
				width: 95%;
			}

			@include max-down(1300px) {
				height: 650px;
			}

			@include max-down(1000px) {
				height: 600px;
			}

			@include max-down(600px) {
				width: calc(100% - 40px);
				height: 550px;
			}
		}
	}

	.form-wrap {
		margin-bottom: 140px;

		.form {
			width: 600px;
			margin: 0 auto;
		}

		@include max-down(1000px) {
			margin-bottom: 100px;
		}

		@include max-down(750px) {
			.form {
				width: 90%;
			}
		}

		@include max-down(600px) {
			margin-bottom: 60px;

			.form {
				width: calc(100% - 40px);
			}
		}
	}

	.page-background.last {
		padding-bottom: 140px;

		@include max-down(1000px) {
			padding-bottom: 100px;
		}

		@include max-down(600px) {
			padding-bottom: 60px;
		}
	}
}