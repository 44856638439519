.page-template-page-admissions {
	.cu-section__stats {
		padding-top: 0;

		@include max-down(1000px) {
			padding-top: 55px;
		}
	}

	#next-steps {
		position: relative;
		padding-bottom: 245px;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -45px;
			right: -25px;
			transform: rotate(-90deg);
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(1160px) {
			.plus-pattern {
				bottom: -75px;
				right: -200px;
			}
		}

		@include max-down(1000px) {
			padding-bottom: 120px;

			.plus-pattern {
				display: none;
			}
		}
	}

	.cu-section__testimonial {
		position: relative;
		z-index: 2;
	}

	.things-to-know-wrap {
		position: relative;
		padding-bottom: 100px;

		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 1300px;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
			background: $lgray;
		}

		.cu-part__expanders {
			width: 1170px;
			position: relative;
			z-index: 2;
			margin: 0 auto;
		}

		@include max-down(1300px) {
			.cu-part__expanders {
				width: 90%;
			}
		}

		@include max-down(600px) {
			.cu-part__expanders {
				width: calc(100% - 40px);
			}
		}
	}

	.cu-section__faculty-list {
		position: relative;
		z-index: 2;

		@include max-down(1000px) {
			padding-top: 0;
		}
	}

	.contact-bar-wrap {
		position: relative;
		padding-bottom: 245px;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: 45px;
			left: -205px;
			background: url('/wp-content/themes/cornerstone-2024/svg/plus-pattern-gray.svg') center no-repeat;
			background-size: contain;
		}
	}
}